.fontSizeRem2 {
	font-size: 2rem !important;
}

.surveySource {
	/*border-style: dotted;*/
	border-width: 2px;
	cursor: pointer;
	/*border-color: #007bff;*/
}

.surveySourceActive {
	border-style: solid;
	border-width: 5px;
	cursor: pointer;
	/*border-color: #007bff;*/

	border-color: #1fb6fc !important;
}

.hand {
	cursor: pointer !important;
}

.questionLayoutTopBorder {
	border-top: 10px solid #1fb6fc !important;
}

.surveyPosterParent {
	width: 20rem;
	position: relative;
}

.surveyPosterLabelParent {
	position: absolute;
	bottom: 1rem;
	right: 1rem;
}

.surveyMediaPoster {
	position: relative;
	height: auto !important;
	min-width: 20rem !important;
	max-width: 20rem !important;
	min-height: 10rem;
	overflow: hidden;
	border: 1px solid rgba(33, 150, 243, 1) !important;
}

.displayNone {
	display: none !important;
}

.surveySupportedLanguagesDropDown {
	margin-bottom: -4rem !important;
	color: #0c0f11 !important;
}

.white {
	color: #ffffff !important;
}

.black {
	color: #0c0f11 !important;
}

.hover-button .hover-button--on,
.hover-button:hover .hover-button--off {
	opacity: 0;
	background-color: rgba(0, 0, 0, 0);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.hover-button:hover .hover-button--on {
	opacity: 1;
	background-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 !important;
}

.rightContainer {
	position: absolute;
	right: 0;
}

.reportsSubContainerMarginTop {
	margin-top: -54px !important;
}

.surveyInfoStatusBadge {
	position: absolute;
	top: -1.15rem;
	left: 3%;
}

.eLearningSecondaryBadge {
	background-color: rgba(91, 69, 96, 0.2) !important;
}

.surveyCloneWindow {
	max-width: 80% !important;
	min-width: 80% !important;
}

.surveyEndDateClear {
	margin-left: 5%;
	color: rgba(33, 150, 243, 1);
	cursor: pointer;
}

.sv_next_btn,
.sv_complete_btn {
	/* margin-top: 3% !important; */
	background-color: #4caf50 !important;
	margin-right: 5px !important;

	padding: 0.6rem 1.75rem 0.6rem 1.75rem !important;
	line-height: 1.2 !important;
	min-width: 10px !important;
	border: transparent;
	min-height: 10px !important;
	font-size: 0.875rem !important;
	font-weight: 400 !important;
	text-transform: capitalize !important;
	border-radius: 0.125rem !important;
}

.sv_prev_btn {
	margin-right: 2rem !important;
}

.button_disabled {
	opacity: 0.7;
}

div[tabindex='-1'] {
	z-index: 0 !important;
}

.translationsDropDownContainer {
	min-height: 20rem !important;
}

.loaderstyle {
	color: red;
}

.cloudDownloadIcon svg {
	color: #a9a9a9;
}

#questionnaireList::-webkit-scrollbar,
#surveyList::-webkit-scrollbar {
	width: 0px;
	background: transparent;
}

#questionnaireList span,
#surveyList #scroll-container {
	position: relative;
	width: 100%;
}

.summaryTableScrollHeader .public_fixedDataTable_header,
.summaryTableScrollHeader .public_fixedDataTable_scrollbarSpacer,
.summaryTableScrollHeader
	.public_fixedDataTable_header
	.public_fixedDataTableCell_main {
	background-color: #2196f3 !important;
	background-image: linear-gradient(#2196f3, #2196f3) !important;
	color: #ffffef !important;
}

.reportFilterDivider {
	border-top: 1px solid #aaaaaa;
}

.surveyErrorDot {
	position: absolute;
	height: 12px;
	width: 12px;
	right: 0.75rem;
	top: 12px;
	z-index: 5;
	background-color: rgba(255, 0, 0, 0.5);
	border-radius: 50%;
	box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 50%);
}

.surveyLanguageErrorDot {
	position: absolute;
	height: 12px;
	width: 12px;
	right: 0px;
	top: 14px;
	background: url('/vendors/wovo/icons/red_dot.svg') no-repeat center center;
	background-size: 100% 100%;
}

.reportFilterIcon {
	position: absolute;
	right: 1.5rem;
	height: 24px;
	width: 24px;
	background: url('/vendors/wovo/dropdown_icon.svg') no-repeat center center;
}

.reportFilterDataIcon {
	display: inline-block;
	margin-right: 6px;
	height: 20px;
	width: 20px;
	vertical-align: bottom;
}

.chartIcon {
	background: url('/vendors/wovo/chart_icon.svg') no-repeat center center;
	background-size: contain;
}

.chartIconBlue {
	background: url('/vendors/wovo/chart_icon_blue.svg') no-repeat center center;
	background-size: contain;
}

.tableIcon {
	background: url('/vendors/wovo/table_icon.svg') no-repeat center center;
	background-size: contain;
}

.tableIconBlue {
	background: url('/vendors/wovo/table_icon_blue.svg') no-repeat center center;
	background-size: contain;
}

.excelIcon {
	background: url('/vendors/wovo/rawdata_icon.svg') no-repeat center center;
	background-size: contain;
}

.downloadlIconBlue {
	background: url('/vendors/wovo/download_icon_blue.svg') no-repeat center
		center;
	background-size: contain;
}

.reportFilterMenuArrow {
	background: url('/vendors/wovo/arrow_icon.svg') no-repeat center center;
	width: 1.5em;
	height: 1.5em;
	display: inline-block;
	align-self: center;
}

.reportFilterTitle {
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	color: #4f4f4f;
}

.reportFilterDemographic {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #4f4f4f;
}

.reportFilterIcon_flip {
	transform: rotate(180deg);
	transition: width 1s;
}

.transparentButton {
	box-shadow: 0 0 transparent !important;
	color: #2196f3 !important;
	background-color: transparent;
	font-weight: 500;
}

.reportTitleLogo {
	position: absolute;
	height: 2.5rem;
	width: 2.5rem;
	background: url('/vendors/wovo/wovo.svg') no-repeat center center;
	background-size: contain;
}

.reportTitle {
	font-style: normal;
	font-weight: normal;
	font-size: 1.9rem;
	line-height: 1.9rem;
	display: flex;
	align-items: center;
	margin-left: 2.8rem;
	color: #4f4f4f;
	margin-top: 0.4rem;
}

.reportTitleDivider {
	border-bottom: 1px solid #aaaaaa;
}

.reportTitleCalenderIcon {
	position: absolute;
	right: 0rem;
	top: 0.75rem;
	height: 24px;
	width: 24px;
	background: url('/vendors/wovo/reports_time_range.svg') no-repeat center
		center;
	cursor: grab;
}

.reportTitleCalenderContainer {
	position: absolute;
	right: 36px;
	top: 1rem;
	cursor: grab;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #4f4f4f;
}

.DateRangeWrapper {
	width: 100% !important;
}

.connectReportChips {
	right: 0px;
	width: auto;
	position: absolute;
}

.connectReportChipsTitle {
	font-style: normal;
	font-weight: normal;
	font-size: 1.5rem;
	line-height: 1.5rem;
	display: flex;
	align-items: center;
	color: #4f4f4f;
}

.reportTileTitle {
	font-style: normal;
	font-weight: normal;
	font-size: 1.4rem;
	line-height: 1.4rem;
	display: flex;
	align-items: center;
	color: #4f4f4f;
	margin-top: 0.4rem;
}

.dashboardtiles .net-chart {
	min-height: 150px !important;
}

.dashboardtiles .jr-card-body {
	width: 100%;
}

.dashboardtiles .title {
	font-size: 1.3rem;
}

.dashboardtiles .subtitle {
	font-size: 0.9rem;
}

.dashboardtiles .extraTitle {
	font-size: 0.9rem;
}

.dashboardtiles .extraSubTitle {
	font-size: 0.7rem;
}

#jsd-widget {
	margin-right: 12px;
}

.eLearningValues {
	font-weight: 600;
}

.reportStickerBronze {
	position: absolute;
	right: 1.5rem;
	height: 24px;
	width: 24px;
	background: url('/vendors/wovo/bronze_poly.svg') no-repeat center center;
}

.reportStickerBronzeInline {
	position: absolute;
	left: 0;
	height: 24px;
	width: 24px;
	background: url('/vendors/wovo/bronze_poly.svg') no-repeat center center;
}

.reportStickerSilver {
	position: absolute;
	right: 1.5rem;
	height: 24px;
	width: 24px;
	background: url('/vendors/wovo/silver_poly.svg') no-repeat center center;
}

.reportStickerSilverInline {
	position: absolute;
	left: 0;
	height: 24px;
	width: 24px;
	background: url('/vendors/wovo/silver_poly.svg') no-repeat center center;
}

.reportStickerGold {
	position: absolute;
	right: 1.5rem;
	height: 24px;
	width: 24px;
	background: url('/vendors/wovo/gold_poly.svg') no-repeat center center;
}

.reportStickerGoldInline {
	position: absolute;
	left: 0;
	height: 24px;
	width: 24px;
	background: url('/vendors/wovo/gold_poly.svg') no-repeat center center;
}

.stickerLabelRow {
	height: 40px;
	margin-top: 8px;
}

.stickerLabelContainer {
	position: absolute;
	right: 0;
}

.stickerLabelInnerContainer {
	position: relative;
	margin-left: 24px;
}

.stickerLabel {
	position: relative;
	margin-left: 32px;
	font-size: 1rem;
}

.stickerLabelBronze {
	color: #f3b27c;
}

.stickerLabelSilver {
	color: #b3b3b3;
}

.stickerLabelGold {
	color: #e7c16c;
}

.clientModulesTitle {
	font-weight: 600;
	font-size: 1rem !important;
}

.clientModulesTitleParent {
	margin-top: -2rem !important;
}

.MuiInputBase-input {
	letter-spacing: normal !important;
}

.mandatory_star {
	color: red;
	padding-left: 1rem;
	font-size: 1.2rem;
}

.optionBuilderIcon {
	background: url('/vendors/wovo/option-builder.svg');
	width: 24px;
	height: 24px;
}

.optionBuilderIconGray {
	filter: grayscale(100%);
}

.text_color_primary {
	color: #2196f3 !important;
}

.text_color_danger {
	color: #fb2424 !important;
}

.button_text_color_danger {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: #fb2424;
}

.button_text_color_primary {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: #2196f3;
}

.button_text_color_disabled {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: #aaaaaa !important;
}

.button_text_color_white {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: #ffffff;
}

.text_color_white {
	color: #ffffff !important;
}

.button_primary {
	background-color: #2196f3 !important;
	border: 1px solid #2196f3 !important;
	box-shadow: none !important;
	border-radius: 4px !important;
}

.button_primary_border {
	background-color: #ffffff !important;
	border: 1px solid #dfdbdb !important;
	color: #2196f3 !important;
	box-shadow: none !important;
	border-radius: 4px !important;
}

.button_primary_border:hover {
	background-color: #2196f3 !important;
	border: 1px solid #ffffff !important;
	color: #ffffef !important;
}
.button_primary_border:hover * {
	color: #ffffef !important;
}

.survey_translations_layout_title {
	font-weight: 400 !important;
	color: #000000;
	font-size: 1.5rem;
	line-height: 1.6rem;
}

.survey_translations_layout_info {
	font-weight: 400 !important;
	color: #000000;
	font-size: 1rem;
	line-height: 1.1rem;
}

.survey_info_widget .card-header {
	padding-left: 18px;
	padding-right: 18px;
}

.survey_info_widget .header_title {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #4f4f4f;
	padding-left: 14px;
	width: 60%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.tooltip_style {
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4rem;
	letter-spacing: 1px;
	color: #ffffff;
	padding-left: 14px;
}

.popup_content {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.15px;
	color: #4f4f4f;
}

.survey_status_badge {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	text-transform: uppercase;
	color: #ffffff;

	align-items: center;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
		0px 1px 3px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	padding: 8px 8px 6px 8px !important;
}

.survey_info_widget .survey_language_badge {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
	color: #000000;
	align-items: center;
	padding: 5px 0px 3px 8px;
	border-radius: 4px;
	letter-spacing: 0.4px;
}

.survey_info_widget .right_action_container {
	position: absolute;
	right: 0;
	top: 0.7rem;
	padding-right: 18px;
}

.survey_info_widget_info_tile {
	height: 94px !important;
}

.survey_info_widget_responses {
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	letter-spacing: 0.1px;
	color: #4f4f4f;
	line-height: 94px;
}

.survey_info_widget_responses_count {
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.1px;
	color: #4f4f4f;
	line-height: 94px;
	font-size: 48px;
}

.survey_info_widget_qr_code_img {
	height: 50px;
	margin-top: 22px;
	margin-left: 28px;
	position: relative;
}

.survey_info_widget_qr_code_button_container {
	height: 50px;
	left: 100px;
	right: 0;
	position: absolute;
	float: right;
	top: 28px;
	z-index: 1000;
}

.survey-audience-filter .pr-0 .MuiChip-root {
	background-color: #2196f3;
	margin: 5px 5px 5px 0;
	border-radius: 4px;
}

.survey-audience-filter .pr-0 .MuiChip-root {
	background-color: #2196f3;
	margin: 5px 5px 5px 0;
	border-radius: 4px;
}

.survey_info_widget .draft {
	background-color: #f9b621 !important;
}

.survey_info_widget .active {
	background-color: #3ab55c !important;
}

.survey_info_widget .inactive {
	background-color: #575757 !important;
}

.survey_info_widget .deleted {
	background-color: #575757 !important;
}

.survey_info_widget .scheduled {
	background-color: #2196f3 !important;
}

.survey_info_widget .completed {
	background-color: #575757 !important;
}

.survey_info_widget .icon_info_header {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #aaaaaa;
}

.survey-audience-filter-clear-all {
	font-size: 12px;
	font-weight: 400;
	color: #2196f3;
	cursor: pointer;
}

.survey-audience-filter-clear-all .MuiSvgIcon-root {
	position: relative;
	top: -2px;
	font-size: 1rem;
}

.survey-audience-filter-applied {
	background-color: #edf4f9;
}

.survey_info_widget .icon_info_content {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #4f4f4f;
	/*    margin-left: 32px;*/
}

.survey_info_widget_popover_text {
	min-width: 80px;
	padding-left: 8px;
	padding-right: 8px;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.4px;
	color: #4f4f4f;
}

.survey_info_widget_redeploy_text {
	min-width: 80px;
	padding-left: 8px;
	padding-right: 8px;
	font-style: normal;
	font-weight: normal;
	color: #4f4f4f;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
}

.survey_info_widget_redeploy_text_selected {
	font-weight: 500 !important;
}

.new_survey_dailog .MuiPaper-root {
	padding: 40px;
}

.survey_translations_dialog .MuiDialog-paper {
	min-width: 80% !important;
}

.survey_translations_dialog .MuiButton-label {
	color: #2196f3 !important;
	font-weight: 500 !important;
}

.survey_translations_dialog .dropzone-file-btn {
	width: 100% !important;
	border: 2px dashed #aaaaaa;
}

.survey_translations_dialog .infoLabel {
	position: absolute;
	font-style: normal;
	font-weight: 500;
	font-size: 1.35rem;
	line-height: 1.5rem;
	color: #aaaaaa;
	margin-left: 48px;
	letter-spacing: 0.15px;
}

.survey_translations_dialog .activeContentDialog {
	background: #e0f0fe;
	border: 2px dashed #2196f3;
	box-sizing: border-box;
}

.survey_translations_dialog .filesInfoLabel {
	position: absolute;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.15px;
	color: #2196f3;
	text-decoration: underline;
}

.survey_translations_dialog li {
	list-style-type: none;
}

.survey_translations_dialog .browse_button {
	display: block;
	height: 36px;
	margin-left: auto;
	margin-right: auto;
}

.survey_translations_dialog .drag_and_drop_container {
	position: relative;
	height: 100%;
	width: 100%;
}

.drag_drop_container_payslip {
	height: 100%;
	width: 100%;
}

.survey_translations_dialog .drag_and_drop_button_container {
	position: absolute;
	height: auto;
	width: 100%;
	bottom: 0;
}

.survey-invite-left-filter {
	width: 100%;
	height: 100%;
	margin: 0 !important;
	max-height: 100% !important;
	max-width: 100% !important;
	background: transparent !important;
}

.surveyListStatus {
	position: absolute;
	margin-top: -0.75rem !important;
}

.surveyStatusLabel {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
	color: #ffffff;
}

.surveyStatusLabelDraft {
	background: #f9b621;
}

.surveyStatusLabelActive {
	background: #3ab55c;
	padding-left: 11px;
	padding-right: 11px;
}

.surveyStatusLabelClosed {
	background: #575757;
}

.align-center {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.align-center-row {
	display: flex;
	justify-content: center;
	flex-direction: row;
	align-items: center;
}

.surveyItemTile {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.surveyItemTileTextContainer {
	position: relative;
	width: 100%;
	height: 50px;
}

.surveyItemTileTextContainerRightMargin {
	border-right: 1px solid #aaaaaa;
}

.surveyItemTileTextNumber {
	position: relative;
	width: 100%;
	text-align: center;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #3f3f3f;
}

.surveyItemTileText {
	position: relative;
	width: 100%;
	text-align: center;
	letter-spacing: 0.15px;
	color: #3f3f3f;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	margin-top: 4px;
}

.surveyItemTileStatusText {
	position: relative;
	width: 100%;
	text-align: center;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 1.25px;
	text-transform: uppercase;
	color: #2196f3 !important;
	margin-left: 22px;
}

.surveyItemTileDeleteText {
	position: relative;
	width: 100%;
	text-align: center;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 1.25px;
	text-transform: uppercase;
	color: #b00020 !important;
	margin-left: 22px;
}

.surveyItemTileIconContainer {
	position: absolute;
	top: 18px;
	left: 20%;
}

.surveyItemTileIconTextContainer {
	position: absolute;
	top: 18px;
}

.surveySeriesQuestionListContainer {
	height: 100px;
	margin-bottom: 1.5rem !important;
	cursor: pointer;
}

.surveySeriesQuestionsList {
	min-height: 400px;
	background-color: #f2f2f2 !important;
}

.surveyListContainer {
	height: 124px;
	margin-bottom: 1.5rem !important;
	cursor: pointer;
}

.surveyListContainer_no_margin_bottom {
	height: 124px;
	cursor: pointer;
}

.redeployedSurveyListContainer {
	height: 114px;
	cursor: pointer;
	margin-top: 4px !important;
}

.redeployedSurveyListContainerItem {
	height: 100%;
	background-color: #f0eded !important;
}

.redeployedTitleContainer {
	position: absolute;
	left: 30px;
	right: 30px;
	top: 25px;
	bottom: 24px;
}

.margin_bottom {
	margin-bottom: 1.5rem !important;
}

.seriesQuestionCheckbox {
	position: absolute;
	right: 20px;
	top: 32px;
	float: right;
}

.seriesQuestionTypeTitle {
	position: absolute;
	left: 20px;
	right: 80px;
	top: 14px;
	bottom: 24px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: #aaaaaa;
}

.seriesQuestionTitleContainer {
	position: absolute;
	left: 20px;
	right: 80px;
	top: 44px;
	bottom: 24px;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: #4f4f4f;
}

.titleContainer {
	position: absolute;
	left: 30px;
	right: 30px;
	top: 30px;
	bottom: 24px;
}

.surveyListTileName {
	position: relative;
	max-width: 85%;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.18px;
	color: #4f4f4f;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	vertical-align: middle;
}

.surveyListTileQuestionnaireInfo {
	position: relative;
	width: 100%;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #b5b5b5;
	margin-top: 6px;
	height: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.surveyListTileDateInfo {
	position: relative;
	width: 100%;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #aaaaaa;
	margin-top: 6px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.surveyNavigationContainer {
	position: relative;
	width: 100% !important;
}

.surveyNavigationSubContainer {
	position: relative;
	height: 78px !important;
}

.surveyNavigationBackButtonContainer {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 12px;
}

.surveyNavigationStatusContainer {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.alignCenter {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.horizontal_center {
	margin: 0;
	position: absolute;
	left: 50%;
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}

.vertical_center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.surveyNavigationStatus {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #8d8d8d;
}

.surveyNavigationStatusInactive {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #8d8d8d;
	opacity: 0.5;
}

.surveyNavigationStatusActive {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #2196f3 !important;
}

.survey_navigation_icon_forward {
	right: 0;
}

.wovo_cookie_banner {
	display: flex;
	color: #000;
	background-color: #fff;
	opacity: 1;
	transform: translateY(0);
	z-index: 2147483647;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 50px 0 50px;
}

.wovo_cookie_banner_content {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	align-content: center;
	margin-top: 0;
	margin-bottom: 0;
	justify-content: space-between;
	padding: 16px 0;
}

.wovo_cookie_banner_button {
	color: #fff;
	background-color: #000;
	border: solid 1px #000;
	text-overflow: ellipsis;
	margin-top: 0;
	margin-bottom: 0;
	height: 32px;
	padding: 0 16px 0 16px;
}

.survey_navigation_filter_label {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #4f4f4f;
}

.quick_insights_ls_header {
	position: relative;
	left: 0;
	right: 0;
	height: 72px;
	width: 100%;
	border-top: 1px solid #aaaaaa;
	border-bottom: 1px solid #aaaaaa;
}

.quick_insight_ls_logo {
	position: relative;
	height: 44px;
	width: auto;
}

.quick_insights_ls_header_label {
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.18px;
	margin-left: 16px;
}

.quick_insights_ls_scoring_content {
	position: relative;
	width: 100%;
	border-bottom: 1px solid #aaaaaa;
}
.quick_insights_ls_scoring_label {
	position: relative;
	width: 100%;
	text-align: center;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #4f4f4f;
}

.quick_insights_ls_scoring_label_sup {
	font-style: normal;
	font-weight: normal;
	font-size: 9px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #797979;
}

.quick_insights_ls_scoring_label_container {
	position: absolute;
	right: 0;
}

.report_scoring_rating_icon {
	position: relative;
	width: 22px;
	height: 22px;
	border-radius: 11px;
	margin-top: 10px;
	margin-right: 22px;
}

.report_scoring_rating_blue {
	background: #1141bb;
}

.report_scoring_rating_green {
	background: #097c14;
}

.report_scoring_rating_orange {
	background: #ffb300;
}

.report_scoring_rating_grey {
	background: #969696;
}

.report_total_response_count_black {
	background: #4f4f4f;
}

.report_response_type_lable_color {
	color: #8d8d8d;
}

.report_scoring_rating_label {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.5px;
	position: absolute;
	float: left;
	left: 44px;
	padding-top: 4px;
}

.report_scoring_rating_label_blue {
	color: #1141bb;
}

.report_scoring_rating_label_green {
	color: #097c14;
}

.report_scoring_rating_label_orange {
	color: #ffb300;
}

.report_scoring_rating_label_container {
	position: relative;
	padding-top: 12px;
	padding-bottom: 28px;
}

.quick_insights_ls_scoring_number {
	position: relative;
	width: 7.5rem;
	height: 5rem;
	font-style: normal;
	font-weight: bold;
	font-size: 36px;
	line-height: 18px;
	color: #ffffff;
	border-radius: 6px;
}

.quick_insight_filter_container {
	position: absolute;
	right: 0;
	padding-top: 10px;
}

.quick_insight_question_title {
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	color: #4f4f4f;
}

.quick_insights_question_scoring_label_container {
	position: relative;
	width: auto;
	height: auto;
}

.quick_insights_question_scoring_label {
	position: relative;
	width: 100%;
	text-align: center;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #4f4f4f;
}

.quick_insights_question_scoring_number {
	position: relative;
	width: 5.5rem;
	height: 3.5rem;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 18px;
	color: #ffffff;
	border-radius: 6px;
}

.quick_insights_question_container {
	border-bottom: 0.5px solid #aaaaaa;
	padding-bottom: 2.5%;
	margin-bottom: 2.5%;
}

.quick_insight_graph_label_vertical {
	padding-top: 8px;
	padding-bottom: 8px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #646464;
	transform: rotate(-90deg);
	float: left;
	position: absolute;
	top: 9rem;
}

.quick_insight_graph {
	margin-left: 4%;
}

.quick_insight_graph_info_label {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.5px;
	color: #4f4f4f;
}

.ls_popup_title {
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 20px !important;
	line-height: 24px !important;
	letter-spacing: 0.15px !important;
	color: #4f4f4f !important;
}

.ls_popup_ok_button {
	background-color: #2196f3 !important;
	color: #ffffff !important;
	margin-bottom: 8px !important;
	margin-right: 8px !important;
	margin-left: 8px !important;
}

.ls_popup_ok_button_label {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 1.25px;
	text-transform: uppercase;
	color: #ffffff;
}

.ls_popup_cancel_button {
	color: #2196f3 !important;
}

.ls_popup_cancel_button_label {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 1.25px;
	text-transform: uppercase;
	color: #2196f3;
}

.ls_survey_snack_bar {
	position: absolute;
	width: auto;
	background-color: rgba(0, 0, 0, 0.87);
	height: auto;
	z-index: 10000;
	right: 0;
	top: 0;
	color: #ffffff;
	border-radius: 4px;
}

.ls_survey_snack_bar .header {
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
}

.ls_survey_snack_bar .content {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
}

.ls_text_ellipsis {
	position: relative;
	min-height: 40px;
	overflow: hidden !important;
}

.ls_text_ellipsis:before {
	content: '&nbsp;';
	visibility: hidden;
}

.ls_text_ellipsis_text {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	padding-bottom: 20px;
}

.survey_setting_randomize_section {
	align-items: center;
	border: 1px solid #f0eded;
}

.survey_setting_popup_button {
	background: #ffffff;
	opacity: 0.8;
	border: 1px solid #dfdbdb;
	border-radius: 4px;
}

.MuiTooltip-popper {
	z-index: 9999999 !important;
}

.singleMultipleTab .MuiTab-root {
	font-size: 20px;
	letter-spacing: 0.15px;
	text-transform: none;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
}

.singleMultipleTab .MuiTab-textColorPrimary {
	color: #b5b5b5;
}

.singleMultipleTab .MuiButton-textPrimary,
.MuiCheckbox-colorPrimary.Mui-checked,
.MuiTab-textColorPrimary.Mui-selected {
	color: #292d2d;
}

.survey_setting_popup_title {
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.18px;
	color: #000000;
}

.survey_setting_popup_content {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.15px;
}

.survey_setting_popup_button_label {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: #4f4f4f;
}

.survey_setting_popup_button {
	background: #ffffff;
	opacity: 0.8;
	border: 1px solid #d7d8d9 !important;
	border-radius: 4px !important;
	padding: 11.5px 24px;
}

.survey_quick_insight_display_Score_label {
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.1px;
}

.survey_quick_insight_display_response_count {
	width: 10rem;
}

.survey_quick_insight_display_Score {
	width: 7rem;
}

.survey_quick_insight_number_display {
	height: 5rem;
	border-radius: 6px;
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 18px;
	color: #ffffff;
	position: relative;
}

.survey_quick_insights_response_count {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.5px;
	color: #4f4f4f;
}

.reportFilterMenuArrowActive {
	background: url('/vendors/wovo/arrowActive.svg') no-repeat center center;
	width: 1.5em;
	height: 1.5em;
	display: inline-block;
	align-self: center;
}

.tableCell {
	border: none !important;
	border-right-style: none !important;
	border-left-style: none !important;
}

.summaryTable .fixedDataTableLayout_hasBottomBorder {
	border-bottom-style: none !important;
}

.summaryTable .fixedDataTableCellLayout_main,
.summaryTable .fixedDataTableColumnResizerLineLayout_main,
.summaryTable .fixedDataTableRowLayout_fixedColumnsDivider {
	border-right-style: none;
}

.summaryTable .public_fixedDataTableRow_fixedColumnsDivider {
	border-color: transparent;
}

.summaryTable .public_fixedDataTable_bodyRow {
	border-bottom: 1px solid #f0eded;
}

.summaryTable .public_fixedDataTableCell_main,
.summaryTable
	.public_fixedDataTableRow_highlighted
	.public_fixedDataTableCell_main {
	background-color: #ffffff;
}

.summaryTable .public_fixedDataTable_header {
	border-bottom: 1px solid #f0eded;
	background-image: linear-gradient(#ffffef, #ffffef) !important;
	color: #ffffef !important;
}

.summary_table_filters_margin_right {
	margin-right: 8rem;
}

.summary_table_filters_padding_left {
	padding-left: 12px;
}

.summaryTable .public_fixedDataTable_header .public_fixedDataTableCell_main {
	font-size: 1rem;
}

.summaryTable .tbl_header_clamp {
	text-align: center;
	display: -webkit-inline-box !important;
}

.summary_table_text_alignment {
	text-align: center;
}

.summaryTable_title_column {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
	color: #4f4f4f;
}

.summaryTable_header {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.15px;
	color: #292d2d;
	background: '#FFFFFF' !important;
}

.summary_table_data {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.5px;
}

.survey_assigned_companies_popup_title {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
}

.survey_assigned_companies_dialog_button {
	justify-content: center;
	align-items: center;
	background: #2196f3 !important;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14);
	border-radius: 4px;
	height: 33px;
}

.survey_assigned_companies_dialog_button_label {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: #ffffff;
}

.survey_assigned_companies_dialog_company {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
}

.assigned_companies_popup_tab .MuiButton-textPrimary,
.MuiCheckbox-colorPrimary.Mui-checked,
.MuiTab-textColorPrimary.Mui-selected {
	color: #292d2d !important;
}

.assigned_companies_popup_tab .MuiTab-root {
	text-transform: none;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.15px;
	color: #9da3a3;
}

.assigned_companies_popup_tab .MuiTab-textColorPrimary {
	color: #b5b5b5;
}

.assigned_companies_popup_tab .MuiTab-textColorInherit.Mui-selected {
	color: #292d2d !important;
}

.connect_report_filter_label {
	font-style: normal;
	font-weight: 600;
	letter-spacing: 0.15px;
	color: #4f4f4f;
}

.connect_report_filter_title_label {
	font-size: 20px;
	line-height: 24px;
}

.connect_report_filter_type_label {
	font-size: 16px;
	line-height: 19px;
}

.connect_report_filter_clear_button {
	border: 1px solid #d7d8d9 !important;
	border-radius: 4px;
}

.connect_report_filter_apply_button {
	border-radius: 4px;
}

.connect_report_filter_button_label {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.report_filter_label_color {
	color: #9da3a3 !important;
}

.notification-container {
	top: auto !important;
	bottom: 0 !important;
	left: 87px !important;
	right: auto !important;
	border-radius: 4px !important;

	.notification-warning,
	.notification-error,
	.notification-success {
		content-color: #ffffff !important;
		background-color: #4a5152 !important;
		border-radius: 4px !important;
	}

	.notification-success {
		color: #86cc4f !important;
	}

	.notification-error {
		color: #f35f5f !important;
	}

	.notification-warning {
		color: #fa005f !important;
	}

	.message {
		font-style: normal !important;
		font-weight: 600 !important;
		font-size: 16px !important;
		line-height: 19px !important;
		color: #ffffff !important;
	}
}

.app-main-header {
	box-shadow: none !important;
	border-bottom: 1px solid #f8f7fa;
}

ul.nav-menu li.menu.open .sub-menu li > a.active:after {
	display: none !important;
}

.pay_slip_table_data {
	padding-left: 30px;
	width: 500px !important;
}

.status {
	padding: 3.5px 10px 3.5px 10px !important;
	border-radius: 30px !important;
	color: #ffffff !important;
	text-align: center;
	margin-right: 32px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	width: fit-content;
}
.scheduled_sending_cancelled_status_bg {
	background-color: #fac849;
}

.failed_status_bg {
	background-color: #f35f5f;
}

.partial_status_bg {
	background-color: #242424;
}

.completed_status_bg {
	background-color: #86cc4f;
}

.draft_status_bg {
	background-color: #808080;
}

.records_showed {
	display: flex;
	align-items: center;
	padding-top: 23.5px;
	width: 121px !important;
	height: 17px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: -0.3px;
	color: #222829;
}

.records_showed span {
	margin-left: 10px;
}

.payslip_preview_header {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: -0.7px;
	color: #171818;
}

.payslip_preview_details {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: -0.3px;
	color: #242424;
}

.payslip_preview_break_down_section {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 30px;
	gap: 10px;
	background: #ffffff;
	border: 1px solid #d7d8d9;
	border-radius: 4px;
}

.payslip_preview_break_down_section_company_name {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: -0.5px;
	color: #171818;
}

.payslip_preview_break_down_section_user_details {
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: -0.1px;
	color: #242424;
}

.payslip_preview_description_label {
	font-weight: 600 !important;
	letter-spacing: 0.4px !important;
}

.payslip_preview_break_down_section_description {
	box-sizing: border-box;
	align-items: flex-start;
	padding: 10px;
	border: 1px solid #d7d8d9;
}

.payslip_preview_break_down_section_table {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	border: 1px solid #d7d8d9;
}

.payslip_preview_break_down_section_table_header {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	color: #242424;
	box-sizing: border-box;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	background: #f8f7fa;
	border: 1px solid #d7d8d9;
}

.payslip_preview_break_down_section_table_header_divider {
	border-width: 0px 0px 0px 2px;
	border-style: solid;
	border-color: #d7d8d9;
}

.payslip_preview_break_down_section_table_content {
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: -0.1px;
	color: #242424;
}

.payslip_preview_break_down_section_table_left_panel {
	padding-top: 10px;
	width: 40%;
	background: #fafafc;
}

.payslip_preview_break_down_section_table_right_panel {
	padding-top: 10px;
	width: 60%;
}

.payslip_preview_break_down_section_table td {
	border-top: none;
	padding-bottom: 2px;
}

.payslip_preview_message {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: -0.3px;
	color: #242424;
}

.payslip_preview_page_divider {
	border: 1px solid #d7d8d9;
}

.payslip_preview_out_reach_label {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-align: justify;
	letter-spacing: -0.5px;
	color: #242424;
}

.active_download_button_with_icon {
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	letter-spacing: 0.5px !important;
	text-transform: uppercase !important;
	color: #2196f3 !important;
	background: #ffffff !important;
	box-shadow: none !important;
}

.payslip_preview_out_reach_details {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	text-align: justify;
	letter-spacing: -0.3px;
	color: #242424;
}

.payslip_preview_title {
	padding: 20px 0px 25px 0px !important;
}

.payslip_preview_active_button {
	justify-content: center !important;
	align-items: center !important;
	padding: 14px 24px !important;
	background: #2196f3 !important;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14) !important;
	border-radius: 4px !important;
}

.payslip_preview_active_button_disable {
	justify-content: center !important;
	align-items: center !important;
	padding: 14px 24px !important;
	background: #aad6fa !important;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14) !important;
	border-radius: 4px !important;
}

.survey_invite_draft_to_open_active_button {
	justify-content: center !important;
	align-items: center !important;
	padding: 14px 24px !important;
	background: #00a080 !important;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14) !important;
	border-radius: 4px !important;
}

.survey_invite_draft_to_open_active_button {
	justify-content: center !important;
	align-items: center !important;
	padding: 14px 24px !important;
	background: #00a080 !important;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14) !important;
	border-radius: 4px !important;
}

.payslip_preview_active_button_disabled {
	background: #aad6fa !important;
	justify-content: center !important;
	align-items: center !important;
	padding: 14px 24px !important;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14) !important;
	border-radius: 4px !important;
}

.payslip_preview_active_button_text {
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	text-align: center !important;
	letter-spacing: 0.5px !important;
	text-transform: uppercase !important;
	color: #ffffff !important;
}

.survey_invite_content_warnings_popup span {
	color: #242424;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.3px;
}

.payslip_preview_non_active_button {
	box-sizing: border-box !important;
	justify-content: center !important;
	align-items: center !important;
	padding: 14px 24px !important;
	background: #ffffff !important;
	border: 1px solid #d7d8d9 !important;
	border-radius: 4px !important;
}

.payslip_preview_non_active_button_text {
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	letter-spacing: 0.5px !important;
	text-transform: uppercase !important;
	color: #2196f3 !important;
}

.payslip_preview_cancel_delivery_dialog_title {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.7px;
	color: #171818;
}

.payslip_preview_out_reach_details_hilighter {
	color: #fb2424 !important;
}

.payslip_date_picker_note {
	color: var(--primary-gray-scale-darker-body, #242424);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.3px;
}

.payslip_date_picker_time_zone_label {
	color: var(--primary-gray-scale-darker-body, #242424);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.3px;
}

.wc_import_menu_button {
	border-radius: 4px !important;
	border: 1px solid var(--primary-gray-scale-lighter, #c7cbcc) !important;
	background: var(--base-surface-white, #fff) !important;
}

.wc_import_menu_button_active,
.wc_import_menu_button:hover {
	border-radius: 4px !important;
	border: 1px solid var(--primary-gray-scale-lighter, #c7cbcc) !important;
	background: var(--secondary-blue-default-blue, #2196f3) !important;
}

.wc_import_menu_button_text_active,
.wc_import_menu_button:hover span span {
	color: var(--base-surface-white, #fff);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Button */
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.wc_import_menu_button_text {
	color: var(--secondary-blue-default-blue, #2196f3);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Button */
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.wc_import_menu_item_text {
	color: var(--primary-gray-scale-darker-body, #242424);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Body 2 */
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.3px;
}

.wc_import_reset_registration_header_label {
	color: var(--primary-gray-scale-darkest-headline, #171818);
	font-feature-settings: 'clig' off, 'liga' off;
	/* H5 */
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -1.1px;
}

.wc_import_reset_registration_upload_section {
	display: flex;
	border-radius: 4px;
	background: var(--base-surface-white, #fff);
}

.wc_import_reset_upload_container {
	position: relative;
	height: 120px;
	width: 100%;
	float: left;
	display: flex;
	padding: 10px;
	align-items: center;
	border-radius: 4px;
	background: var(--surface-background-variant-variant-1, #f8f7fa);
}

.wc_import_reset_upload_container .dropzone-card,
.wc_import_reset_upload_container .dropzone {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.wc_import_reset_upload_container .dropzone-file-btn {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0 !important;
	border: 0;
}

.wc_import_reset_registration_upload_container_heading {
	color: var(--primary-gray-scale-darker-body, #242424);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Subheader */
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	/* 150% */
	letter-spacing: -0.59px;
}

.wc_import_reset_registration_upload_container_sub_heading {
	color: var(--primary-gray-scale-default, #808080);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Caption */
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.3px;
}

.wc_import_reset_registration_upload_container_logo {
	position: relative;
	width: 62px;
	height: 76px;
	margin-top: 6px;
	margin-left: 22px;
	float: left;
}

.wc_import_reset_registration_upload_container_info {
	position: relative;
	width: 70%;
	height: 70%;
	padding-top: 25px;
	padding-left: 30px;
	float: left;
}

.wc_import_reset_registration_download_template_desc {
	color: var(--primary-gray-scale-darker-body, #242424);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Body 2 */
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.3px;
}

.wc_import_reset_registration_upload_validation_message {
	color: var(--tertiary-red-default-error, #f35f5f);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Body 1 */
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.5px;
}

.wc_import_reset_registration_upload_validation_message_success {
	color: var(--primary-gray-scale-darker-body, #242424);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Body 1 */
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.5px;
}

.wc_import_reset_registration_table_column_header {
	color: var(--primary-gray-scale-darker-body, #242424);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Subtitle 1 */
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 19px;
	/* 118.75% */
	letter-spacing: -0.6px;
}

.reset_registration_table_column_file_upload {
	color: var(--primary-gray-scale-darker-body, #242424);
	font-feature-settings: 'clig' off, 'liga' off;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.1px;
	text-decoration-line: underline;
}

.reset_registration_table_column_status_label {
	color: var(--base-surface-white, #fff) !important;
	font-feature-settings: 'clig' off, 'liga' off !important;
	/* Body 2 */
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: normal !important;
	letter-spacing: -0.3px !important;
}

.reset_registration_table_column_status {
	display: flex;
	height: 24px;
	padding: 0px 10px;
	align-items: center;
	border-radius: 30px;
	width: fit-content;
}

.reset_registration_table_column_status_completed {
	background: var(--tertiary-green-lite-default, #86cc4f);
}
.reset_registration_table_column_status_partial {
	background: var(--tertiary-green-lite-default, #242424);
}
.reset_registration_table_column_status_failed {
	background: var(--tertiary-green-lite-default, #f35f5f);
}
.reset_registration_table_column_status_uploading {
	background: var(--tertiary-yellow-default-alert, #fac849);
}

.reset_registration_table_column_data {
	color: var(--primary-gray-scale-darker-body, #242424);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Body 2 */
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.3px;
}

.reset_registration_search_filter {
	border: 1px solid #dfdbdb !important;
	background: #ffffff;
}

.search_dropdown_filter .form-group {
	margin-bottom: 0 !important;
}

.search_text_filter .search_input_container {
	margin-bottom: 0 !important;
}

#ON_SEARCH_FILTER .MuiPopover-paper {
	min-width: 7.6% !important;
}

.wc_import_reset_upload_container .wc_deactivate_popup_contacts_icon {
	margin-top: 24px;
}

.reset_registration_summary_dialog_title {
	color: var(--primary-gray-scale-darkest-headline, #171818);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Title */
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	/* 120% */
	letter-spacing: -0.55px;
}

.reset_registration_summary_dialog_contact_count {
	color: var(--primary-gray-scale-default, #808080);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Body 1 */
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.7px;
}

.reset_registration_summary_dialog_column_header {
	color: var(--primary-gray-scale-darker-body, #242424);
	text-align: justify;
	font-feature-settings: 'clig' off, 'liga' off;
	/* Subtitle 2 */
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.5px;
}

.reset_registration_summary_dialog_divider {
	height: 0.2px;
	background: #242424;
}

.reset_registration_summary_dialog_data {
	color: var(--primary-gray-scale-darker-body, #242424);
	text-align: justify;
	font-feature-settings: 'clig' off, 'liga' off;
	/* Caption */
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.3px;
}

.reset_registration_download_template_button .button_primary_border:hover {
	background: var(--secondary-blue-default-blue, #2196f3) !important;
}

.reset_registration_download_template_button
	.button_primary_border:hover
	span
	span {
	color: var(--base-surface-white, #fff);
}

.response_heading_label {
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	letter-spacing: -0.6000000238418579px;
	text-align: left;
}

.red_dot_icon_container {
	position: relative;
	display: inline-block;
}

.survey_period_border {
	border: 1px solid #c7cbcc;
	border-radius: 4px;
	padding: inherit;
	width: fit-content;
}

.survey_period_position {
	position: static !important;
	border: #c7cbcc;
	border-radius: 4px;
}

.wc_import_menu_button {
	border-radius: 4px !important;
	border: 1px solid var(--primary-gray-scale-lighter, #c7cbcc) !important;
	background: var(--base-surface-white, #fff) !important;
}

.wc_import_menu_button_active,
.wc_import_menu_button:hover {
	border-radius: 4px !important;
	border: 1px solid var(--primary-gray-scale-lighter, #c7cbcc) !important;
	background: var(--secondary-blue-default-blue, #2196f3) !important;
}

.wc_import_menu_button_text_active,
.wc_import_menu_button:hover span span {
	color: var(--base-surface-white, #fff);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Button */
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.wc_import_menu_button_text {
	color: var(--secondary-blue-default-blue, #2196f3);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Button */
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.wc_import_menu_item_text {
	color: var(--primary-gray-scale-darker-body, #242424);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Body 2 */
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.3px;
}

.wc_import_reset_registration_header_label {
	color: var(--primary-gray-scale-darkest-headline, #171818);
	font-feature-settings: 'clig' off, 'liga' off;
	/* H5 */
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -1.1px;
}

.wc_import_reset_registration_upload_section {
	display: flex;
	border-radius: 4px;
	background: var(--base-surface-white, #fff);
}

.wc_import_reset_upload_container {
	position: relative;
	height: 120px;
	width: 100%;
	float: left;
	display: flex;
	padding: 10px;
	align-items: center;
	border-radius: 4px;
	background: var(--surface-background-variant-variant-1, #f8f7fa);
}

.wc_import_reset_upload_container .dropzone-card,
.wc_import_reset_upload_container .dropzone {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.wc_import_reset_upload_container .dropzone-file-btn {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0 !important;
	border: 0;
}

.wc_import_reset_registration_upload_container_heading {
	color: var(--primary-gray-scale-darker-body, #242424);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Subheader */
}

.view_history_popup_title {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24.2px;
	letter-spacing: -0.7px;
}

.down_time_banner {
	left: 0;
	right: 0;
	position: absolute;
	background-color: #f35f5f;
	color: var(--Surface, #fff);
	font-feature-settings: 'clig' off, 'liga' off;
	/* Subtitle 1 */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 19px;
	/* 118.75% */
	letter-spacing: -0.6px;
}

.add_banner_to_top {
	margin-top: 32px !important;
}

.maintenance_page {
	display: inline-flex;
	padding: 150px 200px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	background: var(--base-surface-white, #fff);
	/* Medium */
	box-shadow: 0px 0.5px 8px 0px rgba(0, 0, 0, 0.2);
}

.maintenance_page_inner {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.maintenance_page_inner_heading {
	color: #000;
	font-feature-settings: 'clig' off, 'liga' off;
	/* H4 */
	font-family: Inter;
	font-size: 34px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.9px;
}

.maintenance_page_inner_message {
	color: #000;
	font-feature-settings: 'clig' off, 'liga' off;
	/* Subtitle 1 */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 19px; /* 118.75% */
	letter-spacing: -0.6px;
}

#time-picker-popup {
	z-index: 999999;
}

#time-picker-options-bar::-webkit-scrollbar {
	display: none;
}

#time-picker-options-bar {
	z-index: 99999;
}

.default_info_icon {
	cursor: default !important;
}

.phone-number-helperText {
	.MuiFormHelperText-root.Mui-error {
		color: #f44336 !important;
	}
}
