$primary-color: #2196f3 !default;
$primary-text-color: #ffffff !default;
$primary-color-disabled: #aad6fa !default;

.MuiFab-primary {
	color: $primary-text-color !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid $primary-color !important;
}

.MuiButton-textPrimary,
.MuiCheckbox-colorPrimary.Mui-checked,
.MuiTab-textColorPrimary.Mui-selected {
	color: $primary-color !important;
}

.MuiButton-containedPrimary,
.MuiPickersDay-isSelected,
.MuiPickersToolbar-toolbar,
.MuiFab-primary,
.MuiTabs-indicator {
	background-color: $primary-color !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: $primary-color !important;
}

.MuiInputBase-root.Mui-disabled {
	background-color: rgba(33, 33, 33, 0.08) !important;
	color: #000000 !important;
}

.MuiButton-contained.Mui-disabled {
	background-color: rgba(33, 33, 33, 0.08) !important;
	color: #000000 !important;
	box-shadow: none;
	cursor: not-allowed !important;
}

.MuiFormHelperText-root,
.MuiFormHelperText-root.Mui-disabled {
	color: #000000 !important;
}

// .MuiInputLabel-outlined {
// 	color: #000000 !important;
// }

.MuiFormLabel-root.Mui-focused {
	color: $primary-color !important;
}

.blue ul.nav-menu li.menu.open .sub-menu li > a.active {
	background-color: #e0f0fe !important;
}

.optionPopOver {
	padding: 0%;
	background: #f2f2f2;
}

.optionBlock {
	padding-block: inherit !important;
}

.optionRight {
	padding-right: inherit;
}

.borderedIconTextField {
	border: 1px solid #aaaaaa;
	border-radius: 8px;
	padding: 6px;
}

.surveySearchContainer .Mui-focused {
	border: 1px solid $primary-color !important;
}

.surveySeriesSearchField {
	background-color: #f2f2f2 !important;
}

.surveyLineGraphLineInfo {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.4px;
}

.quick_insight_graph_label {
	width: calc(100% - 270px);
	text-align: center;
	padding-top: 16px;
	padding-bottom: 8px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #646464;
	padding-left: 4%;
	margin-left: 270px;
}

.blue .app-main-header {
	background-color: #ffffff !important;
}

.app-data-container {
	margin-top: 70px;
}

.nav_bar_app_name {
	padding-left: 10px;
	padding-top: 4px;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.18px;
	color: #000000;
}

.nav_bar_app_sub_name {
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.25px;
	color: #4f4f4f;
	padding-left: 22px;
}

.side-nav {
	overflow: hidden !important;
}

.app_header_title {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	color: #333333;
}

.app_header_title_text {
	position: relative;
	float: left;
	margin-top: 4px;
}

.MultiSelectDropDown {
	position: relative;
	width: 100%;
	min-height: 72px;
	padding: 0;
}

.MultiSelectDropDownChild {
	position: absolute;
	left: 0;
	top: 16px;
	right: 0;
	bottom: 0;
	padding: 0;
	border: 1px solid #c4c4c4;
	border-radius: 4px;
	background-color: #ffffff;
	align-items: center;
	box-sizing: border-box;
	color: rgba(0, 0, 0, 0.87);
	display: flex;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.15008px;
	line-height: 19.0016px;
	overflow-wrap: break-word;
	text-align: left;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.MultiSelectDropDownHeader {
	position: absolute;
	font-size: 1rem;
	font-weight: 400;
	color: #000000;
	background-color: #ffffff;
	padding-left: 8px;
	padding-right: 8px;
	transform: translate(0px, 3px) scale(0.75);
}

.MultiSelectDropDownHeaderActive {
	color: $primary-color !important;
}

.MultiSelectDropDownChildActive {
	border: 1px solid $primary-color !important;
	color: #000000 !important;
}

.MultiSelectDropDownContent {
	font-size: 16px;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.7) !important;
	padding-left: 16px;
}

.MultiSelectDropDownContentCheckBox {
	padding: 0 8px 0 0 !important;
}

.MultiSelectDropDownSelectAll {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.25px;
	color: rgba(0, 0, 0, 0.5);
	cursor: pointer;
}

.MultiSelectDropDownItem {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.MuiPopover-paper {
	max-height: 400px !important;
}

.MuiPopover-root {
	z-index: 1600 !important;
}

.tbl_header_clamp {
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	display: -webkit-box !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	line-height: 20px !important;
}

.table_header_title_first {
	margin-left: 22px;
}

.table_header_title {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0.15px;
	color: #4f4f4f;
	padding-right: 8px;
}

.ls_table {
}

.ls_table .MuiTableHead-root {
	height: 44px !important;
	background: #ffffff;
	border-radius: 8px 8px 0px 0px;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0.15px;
	color: #4f4f4f;
	border-bottom: 4px solid #f4f4f7 !important;
}

.ls_table .MuiTableBody-root {
	background-color: #ffffff !important;
}

.ls_table .MuiTableRow-root {
	height: 40px !important;
}

.ls_table_error_info {
	margin-left: 18px;
}

.ls_popup {
	min-width: 400px;
}

.ls_popup_positive_text_color {
	color: #ffffff;
}

.menu_popover_button {
	padding: 8px 2px 10px 4px;
	background-color: #ffffff;
	border-radius: 4px;
	border: 1px solid #dfdbdb;
}

.menu_popover_button_title {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	display: flex;
	letter-spacing: 0.25px;
	color: #333333;
}

.menu_popover_button_icon {
	background: url('/vendors/wovo/arrow_icon.svg') no-repeat center center;
	width: 1.5em;
	height: 1.5em;
	align-self: center;
	position: absolute;
	right: 16px;
}

.menu_popover_item_header {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
	color: #3f3f3f;
}

.menu_popover_item_sub_header {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	color: #8d8d8d;
	margin-top: 4px;
}

.menu_popover_item_sub_header_warning {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	color: #fa005f;
	margin-top: 4px;
	padding-right: 4px;
}

.menu_popover_item_sub_header_parent {
	position: relative;
}

.menu_popover_item_icon {
	display: inline-block;
	margin-right: 12px;
	height: 20px;
	width: 20px;
	vertical-align: bottom;
}

.menu_popover_item_icon_delete {
	background: url('/vendors/wovo/clients/icon_delete.svg') no-repeat center
		center;
	background-size: contain;
}

.menu_popover_item_icon_deactivate {
	background: url('/vendors/wovo/clients/icon_deactivate.svg') no-repeat center
		center;
	background-size: contain;
}

.payslip_upload_circular_success_icon {
	background: url('/vendors/wovo/circle_success.svg') no-repeat center center;
	background-size: contain;
	height: 32px;
	width: 32px;
}

.payslip_upload_failure_message {
	color: red;
}

.payslip_upload_failure_message span {
	color: red !important;
}

.MuiListItem-button:hover {
	background: #cbe7ff !important;
}

.MuiTableRow-root td span {
	color: $table_row_text_color !important;
}

.payslip_table .MuiTableRow-root td .status_label span {
	color: #ffffff !important;
}

.payslip_table .MuiTableRow-head {
	height: 60px !important;
}

.payslip_table .MuiTableRow-body {
	height: 43px !important;
}

.MuiTableRow-root:hover td span {
	color: $table_row_hover_text_color !important;
}
.payslip_table .MuiTableRow-root:hover {
	background: none !important;
}

.MuiTableRow-root:hover,
.MuiTableRow-root:hover td {
	background: $table_row_hover_background !important;
}

.ls_overflow_menu {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 1300;
}

// .MuiDialog-root {
//     z-index: 2001 !important;
// }

.ls_overflow_menu_header {
	position: relative;
	width: 100%;
	height: 72px;
	border: 1px solid #f8f7fa;
}

.ls_overflow_menu_header_title {
	position: relative;
	height: 100%;
	line-height: 72px;
	margin-left: 16px;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	letter-spacing: 0.15px;
	color: #333333;
	width: 80%;
}
.rotate {
	transform: rotate(180deg);
}
.table-sort-label {
	display: flex;
	align-items: center;
}
.table-sort-label-active {
	font-weight: bold;
}

.table-sort-label-active .MuiTableSortLabel-icon {
	color: #2196f3 !important;
}

.MuiTableSortLabel-icon {
	opacity: 1 !important;
	transition: transform ease-in-out 0.15s !important;
	transform-origin: center center !important;
}

.ls_overflow_menu_header_back_button {
	position: absolute;
	float: right;
	right: 28px;
	top: 30px;
	height: 16px;
	width: 16px;
	background: url('/vendors/wovo/icons/common/icon_navigation_back_button.svg')
		no-repeat center center !important;
	background-size: 100% 100%;
}

.ls_overflow_menu_content {
	position: absolute;
	width: 360px;
	height: 100%;
	background-color: #ffffff;
	animation-name: fadeInLeft, hide;
	animation-duration: 1s;
	animation-delay: 0s, 2s;
	animation-fill-mode: both;
}

.ls_overflow_menu_margin {
	position: absolute;
	left: 0;
	right: 0;
	top: 73px;
	bottom: 0;
	overflow: hidden;
}

.ls_overflow_menu_content_left {
	animation-name: fadeInLeft, hide;
}

.ls_overflow_menu_content_right {
	right: 0 !important;
	float: right !important;
	animation-name: fadeInRight, hide !important;
}

.ls_badge_list_item_text {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	color: #4f4f4f;
}

.ls_badge_list_item {
	border: 1px solid red;
}

.ls_active_badge {
	background-color: $primary-color !important;
	margin-top: 8px !important;
	border-radius: 4px !important;
}

.ls_active_badge_label {
	color: #ffffff !important;
}

.ls_active_badge_label_icon {
	background-color: transparent !important;
	width: 40px !important;
	height: 100% !important;
	line-height: 30px !important;
}

.table_filter_action_dropdown {
	position: relative;
	width: 12%;
}

.form_container_input_parent {
	position: absolute;
	width: 100%;
}

.form_container_input_parent_right {
	position: absolute;
	right: 0px;
}

.input_container {
	position: relative;
	margin-top: 0;
	margin-bottom: 14px;
}

.input_container .MuiInputBase-input {
	outline: none !important;
	border: 1px solid #dfdbdb;
	border-radius: 4px;
	height: 30px;
}

.non_border .MuiInputBase-input {
	outline: none !important;
	border-radius: 4px;
	height: 30px;
	border: none !important;
}

.input_container .MuiInput-underline:before {
	border-bottom: 0px solid transparent !important;
}

.input_container .MuiInput-underline:after {
	border-bottom: 0px solid transparent !important;
}

.input_container .MuiInputBase-input input {
	outline: none !important;
}

.input_container .MuiInputBase-input:focus {
	outline: none !important;
	border: 1px solid $primary-color !important;
}

.input_container_text {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	display: flex;
	align-items: center;
	letter-spacing: 0.25px;
	color: #8d8d8d;
	text-align: left;
	padding-top: 20px;
}

.input_container .css-tlfecz-indicatorContainer {
	padding: 2px 8px 2px 8px !important;
}

.search_input_container .MuiInputBase-root {
	outline: none !important;
	max-height: 50px;
	border: 1px solid #dfdbdb;
	border-radius: 4px;
	min-height: 40px;
	background-color: #ffffff;
	width: 100%;
}

.search_input_container .MuiInput-underline:before {
	border-bottom: 0px solid transparent !important;
}

.search_input_container .MuiInput-underline:after {
	border-bottom: 0px solid transparent !important;
}

.search_input_container .MuiInputBase-root input {
	outline: none !important;
}

.search_input_container .MuiInputBase-root:focus {
	outline: none !important;
	border: 1px solid $primary-color !important;
}

.search_input_container {
	position: relative;
	margin-top: 0;
	margin-bottom: 14px;
}

.input_container_child {
	position: relative;
	float: left;
	padding-right: 4px;
}

.input_container_child_single_response_buttons {
	position: relative;
	float: left;
	padding-right: 10px;
	width: 189px;
	height: 45px !important;
	gap: 10px;
	font-weight: 400 !important;
}

.survey_invite_credits_available_indicator_class {
	color: #f35f5f;
}

.input_container_child_single_response_buttons_share {
	position: relative;
	float: left;
	padding-right: 10px;
	width: 189px;
	height: 45px !important;
	gap: 10px;
	font-weight: 400 !important;
}

.input_container_child_single_response_buttons_share {
	button {
		padding: 0;
	}
}
.share_invite_text_class span {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0.5px;
}

.invite_page_open_dropdown_section {
	background-color: #00a080 !important;
	height: 45px !important;
}

.outreach_text_class span {
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.5px;
}

.input_container_child_single_response_buttons {
	position: relative;
	float: left;
	padding-right: 10px;
	width: 189px;
	height: 45px !important;
	gap: 10px;
	font-weight: 400 !important;
}

.input_container_child_single_response_buttons_share {
	position: relative;
	float: left;
	padding-right: 10px;
	width: 189px;
	height: 45px !important;
	gap: 10px;
	font-weight: 400 !important;
}

.input_container_child_single_response_buttons_share {
	button {
		padding: 0;
	}
}
.share_invite_text_class span {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0.5px;
}

.invite_page_open_dropdown_section {
	background-color: #00a080 !important;
	height: 45px !important;
}

.outreach_text_class span {
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.5px;
}

.filter_input_container_child {
	position: relative;
	float: left;
	width: 100%;
}

.table_search_input_dropdown {
	position: relative;
	width: 20%;
}

.float_right {
	float: right;
}

.ls_table .MuiTableFooter-root {
	background-color: #ffffff !important;
}

.ls_table .MuiTableCell-footer {
	padding-top: 16px !important;
	padding-bottom: 16px !important;
}

.ls_table .MuiTablePagination-caption {
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	letter-spacing: 0.25px !important;
	color: #4f4f4f !important;
}

.ls_table .MuiTablePagination-select {
	padding-right: 36px !important;
	border: 1px solid #dfdbdb;
	border-radius: 4px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
	color: #4f4f4f;
}

.ls_table_header_info_icon {
	margin-left: 18px;
}

.ls_table_header_checkbox_icon {
	margin-left: 14px;
}

.ls_header_title_first {
	margin-left: 22px;
}

.ls_table_body_checkbox_icon {
	margin-left: 22px;
}

.ls_theme_vertical_form_container {
	position: relative;
	width: 100%;
	height: auto;
}

.ls_theme_vertical_form_container_label {
	position: relative;
	width: 100%;
	padding: 0 0 10px 0 !important;
	float: left;
}

.ls_theme_vertical_form_container_chip_array_container {
	padding-bottom: 6px !important;
}

.input_container_label {
	position: relative;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
	color: #8d8d8d;
	margin-right: 14px;
	margin-top: 12px !important;
	width: 100%;
	height: 40px !important;
}

.filter_icon_alert_info_parent {
	position: relative;
	width: auto;
	margin-top: 10px;
}

.filter_icon_alert_info_parent_with_padding {
	position: relative;
	width: auto;
	margin-top: 10px;
	padding-bottom: 18px;
}

.filter_icon_alert_info_title {
	position: relative;
	margin-left: 24px;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: 0.1px;
	color: #4f4f4f;
}

.filter_icon_info_title_no_icon {
	position: relative;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: 0.1px;
	color: #4f4f4f;
	float: left;
}

.ls_theme .badge-light {
	background-color: #ffffff;
	color: #4b5152;
	border: 2px solid #dfdbdb;
	padding-left: 8px !important;
	padding-right: 8px !important;
	font-weight: 400 !important;
	font-size: 12px !important;
	line-height: 14px !important;
	letter-spacing: 0.4px !important;
}

.ls_theme .badge-light-date-range {
	background-color: #ffffff !important;
	color: #4f4f4f !important;
	border: 1px solid #dfdbdb;
	padding-left: 8px !important;
	padding-right: 8px !important;
	font-weight: 400 !important;
	font-size: 12px !important;
	line-height: 14px !important;
	letter-spacing: 0.4px !important;
	min-width: 120px;
}

.ls_theme .badge-light-active {
	border: 2px solid $app_default_primary_color !important;
}

.ls_left_side_filter_block {
	position: relative;
	width: 100%;
	height: auto;
	padding-right: 16px;
	padding-left: 16px;
	float: left;
}

.ls_left_side_filter_block_last {
	padding-bottom: 120px;
}

.ls_input_list_non_title_sub_content {
	position: relative;
	width: 100%;
	min-height: 50px;
	margin-bottom: 16px;
	float: left;
}

.ls_input_list_non_title_sub_content .searchWrapper {
	padding: 12px 12px !important;
}

.ls_input_list_non_title_sub_content .searchWrapper .chip {
	border-radius: 4px !important;
	margin-bottom: 10px !important;
	margin-right: 8px !important;
	padding: 3px 10px !important;
}

.ls_left_side_filter_block_active {
	position: relative;
	width: 100%;
	height: auto;
	padding-right: 0;
	padding-left: 0;
	background-color: $app_default_primary_color !important;
}

.ls_left_side_filter_block_dropdown {
	margin-top: -60px;
}

.no_title_input_container {
	position: relative;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
	color: #8d8d8d;
	/*margin-right: 14px;*/
	margin-top: 12px !important;
	width: 100%;
}

.no_title_input {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
}

.ls_left_side_filter_content {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.survey-invite-left-filter .ls_left_side_filter_child_content_applied_filters {
	width: 100%;
}

.class_for_time_picker_width {
	display: flex !important;
	padding-bottom: 8px !important;
}

.ls_left_side_filter_child_content_applied_filters {
	position: relative;
	overflow: hidden;
	width: calc(100% - 32px);
	height: auto;
	background-color: #edf4f9 !important;
	padding-left: 16px;
	padding-right: 16px;
}

.ls_left_side_filter_child_content {
	position: absolute;
	bottom: 88px;
	top: 0px;
	left: 0;
	right: 0;
	overflow: hidden;
}

.ls_left_side_filter_button_content {
	position: absolute;
	bottom: 0;
	height: 88px;
	left: 0;
	right: 0;
	border-top: 1px solid #f8f7fa;
}

.ls_left_side_filter_child_content:hover {
	overflow-y: auto;
	overflow-x: hidden;
}

.ls_left_side_filter_child_content::-webkit-scrollbar {
	width: 8px;
}

.ls_left_side_filter_child_content::-webkit-scrollbar-track {
	background: #faf9fa;
}

.ls_left_side_filter_child_content::-webkit-scrollbar-thumb {
	background: #bdb9c5;
}

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background: #faf9fa;
}

::-webkit-scrollbar-thumb {
	background: #bdb9c5;
}

.filter_button_full {
	position: absolute;
	left: 16px !important;
	right: 16px !important;
	top: 24px !important;
	bottom: 24px !important;
	color: #ffffff !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	letter-spacing: 0.5px !important;
	text-transform: none !important;
	align-items: flex-end !important;
	cursor: pointer;
	background: $primary-color;
	text-align: center;
	line-height: 40px !important;
	border-radius: 4px;
}

.filter_button_full_disabled {
	background: $primary-color-disabled !important;
	cursor: auto;
}

.ls_table_column_text_normal {
	cursor: pointer;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
	color: #222829 !important;
}

.ls_table_column_text_highlight {
	color: #222829 !important;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
	cursor: pointer;
}

.ls_table_column_text_link {
	color: $app_default_primary_color !important;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
	cursor: pointer;
}

.ls_table_first_column {
	padding-left: 24px;
}

.wc_import_upload_container {
	padding: 30px !important;
	background-color: #ffffff;
}

.wc_upload_container {
	position: relative;
	background-color: #f8f7fa;
	height: 120px;
	width: 480px;
	float: left;
}

.payslip_upload_container {
	position: relative;
	background-color: #f8f7fa;
	height: 120px;
	width: 480px;
	float: left;
}

.payslip_new_input_focus:focus {
	border-color: #2196f3 !important;
	border: 2px solid #2196f3 !important;
	box-shadow: none !important;
}

.wc_download_container {
	position: relative;
	background-color: transparent;
	height: 120px;
	width: 400px;
	float: left;
	padding-left: 30px;
}

.ps_download_container {
	position: relative;
	background-color: transparent;
	height: 120px;
	width: 400px;
	float: left;
	padding-left: 30px;
	display: flex;
}

.wc_download_container_label span {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.4px;
	color: #4f4f4f;
}

.ps_download_container_label span {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.4px;
	color: #4f4f4f;
}

.wc_download_container_button {
	position: absolute;
	left: 30px;
	bottom: 0;
}

.ps_download_container_button {
	position: absolute;
	bottom: 0;
}

.wc_upload_container_logo {
	position: relative;
	width: 62px;
	height: 76px;
	margin-top: 6px;
	margin-left: 22px;
	float: left;
}

.payslip_upload_container_logo {
	position: relative;
	width: 62px;
	height: 76px;
	margin-top: 6px;
	margin-left: 22px;
	float: left;
}

.wc_upload_container_info {
	position: relative;
	width: 70%;
	height: 70%;
	padding-top: 8px;
	padding-left: 30px;
	float: left;
}

.payslip_upload_container_info {
	position: relative;
	width: 70%;
	height: 70%;
	padding-top: 8px;
	padding-left: 30px;
	float: left;
}

.wc_upload_container_info_header {
	position: relative;
	margin-top: 10px;
}

.payslip_upload_container_info_header {
	position: relative;
	margin-top: 10px;
}

.payslip_upload_container_info_header span {
	position: relative;
	color: #242424;
	margin-top: 10px;
	width: 570px;
	height: 24px;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.59px;
}

.payslip_view_right_container_padding {
	padding: 50px !important;
}

.payslip_titles_for_inputs span {
	// width: 150px !important;
	word-wrap: none !important;
	min-width: 2rem !important;
	height: 20px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	display: flex;
	align-items: center;
	letter-spacing: -0.3px;
	color: #242424 !important;
}

.payslip_titles_for_inputs {
	word-wrap: none !important;
}

.payslip_date_time_expired_style span {
	color: #f35f5f !important;
	font-size: 10px !important;
	padding-top: 0 !important;
	float: right;
	display: flex;
	width: 71%;
	flex-direction: column;
}

.wc_upload_container_info_header span {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #4f4f4f;
}

.wc_upload_container_info_sub_header {
	position: relative;
	margin-top: 0px;
}

.payslip_upload_container_info_sub_header {
	position: relative;
	margin-top: 0px;
}

.wc_upload_container_info_sub_header span {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	color: #8d8d8d;
}

.payslip_upload_container_info_sub_header span {
	width: 570px;
	height: 15px;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: -0.3px;
	color: #808080;
}

.payslip_upload_container_info_sub_header_error span {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
	color: #fa005f;
}

.payslip_upload_container_info_sub_header_verify span {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
	color: #242424;
}

.payslip_upload_container_info_sub_header_success span {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
	text-decoration-line: underline;
	color: #2196f3;
}

.wc_upload_container .dropzone-card,
.wc_upload_container .dropzone {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.wc_upload_container .dropzone-file-btn {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0 !important;
	border: 0;
}

.wc_import_table_view_log {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	letter-spacing: 0.5px;
	color: $app_default_primary_color;
}

.wc_import_status_content {
	position: relative;
	min-width: 420px;
	min-height: 150px;
}

.wc_import_status_processed span {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	text-align: right;
	letter-spacing: 0.4px;
	color: #4f4f4f;
}

.wc_import_status_table {
	position: relative;
	width: 100%;
	height: auto;
	padding-top: 20px;
}

.wc_import_status_table_header {
	position: relative;
	width: 100%;
	height: 22px;
}

.wc_import_status_table_date {
	position: relative;
	float: left;
}

.wc_import_status_table_details {
	position: relative;
	float: right;
}

.wc_import_status_table_date span,
.wc_import_status_table_details span {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-align: justify;
	letter-spacing: 0.1px;
	color: #4f4f4f;
}

.wc_import_status_table_dates {
	position: relative;
	border-bottom: 0.2px solid #4f4f4f;
	border-top: 0.2px solid #4f4f4f;
	min-height: 20px;
	width: 100%;
	padding-top: 4px;
	padding-bottom: 4px;
}

.wc_import_status_table_content_row {
	position: relative;
	width: 100%;
	height: 30px;
	padding-top: 8px;
	padding-bottom: 4px;
}

.wc_import_status_table_content_row_label {
	position: relative;
	float: left;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	text-align: justify;
	letter-spacing: 0.4px;
	color: #4f4f4f;
}

.wc_import_status_table_content_row_data {
	position: relative;
	float: right;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	text-align: right;
	color: #797979;
}

.wc_import_status_summary_container {
	position: relative;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 20px;
}

.wc_import_status_table_summary_label {
	position: relative;
	float: left;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	color: #4f4f4f;
}

.wc_import_status_table_summary_data {
	position: relative;
	float: right;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	text-align: right;
	letter-spacing: 0.4px;
	color: #4f4f4f;
}

.wc_groups_table_name {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
	color: #333333;
}

.label_container {
	position: absolute;
	background-color: #ffffff;
	cursor: pointer;
	width: auto;
	height: auto;
	padding: 12px 24px 12px 24px;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	letter-spacing: 0.5px !important;
	text-transform: none !important;
	align-items: flex-end !important;
	color: #222829 !important;
	border-radius: 4px !important;
	white-space: nowrap;
	border: 1px solid #4b5152;
}

.staticActionButton {
	border: 1px solid $app_default_primary_color !important;
	background-color: $app_default_primary_color !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	letter-spacing: 0.5px !important;
	text-transform: none !important;
	align-items: flex-end !important;
	color: #ffffff !important;
}

.staticActionButtonDisabled {
	border: 1px solid #aad6fa !important;
	background-color: #aad6fa !important;
}

.staticActionButtonNegative span,
.staticActionButton span {
	padding: 2px !important;
}

.staticActionButtonNegative {
	border: 1px solid #d7d8d9 !important;
	background-color: #ffffff !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	letter-spacing: 0.5px !important;
	text-transform: none !important;
	align-items: flex-end !important;
	color: $app_default_primary_color !important;
}

.container_sub_header {
	min-height: 44px !important;
}

.container_sub_header_title {
	padding: 20px !important;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #4f4f4f;
}

.container_sub_header_title_payslip_view {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #4f4f4f;
}

.container_sub_header_title_no_left_padding {
	padding: 20px 20px 20px 4px !important;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #4f4f4f;
}

.wc_basic_info_container {
	position: relative;
	float: right;
	width: calc(99% - 220px);
}

.wc_basic_info_sub_container {
	position: relative;
	width: 100%;
	height: auto;
	margin-top: -20px;
}

.client_basic_info_sub_container {
	position: relative;
	width: 100%;
	height: auto;
	margin-top: 0px;
}

.wc_basic_info_sub_container_row {
	position: relative;
	width: 100%;
	height: auto;
}

.wc_standard_demographic_container .form_container_input_parent,
.wc_basic_info_container .form_container_input_parent {
	position: relative;
	width: 65% !important;
	float: right;
	overflow: hidden;
}

.form_container_label_parent {
	position: relative;
	width: 35%;
}

.form_container_root {
	position: relative;
	width: 100%;
	float: left;
}

.form_container_root_no_padding {
	position: relative;
	width: 100%;
	float: left;
	padding: 0 !important;
}

.form_container_root_next_dropdown {
	margin-top: -40px;
}

.ls_theme_box_card .form_container_label_content span,
.form_container_root .form_container_label_content label {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.25px;
	color: #4b5152;
	position: relative;
}

.ls_payslip_download_display {
	display: flex;
}

.invite_page_col_display {
	display: flex;
}

.invite_page_outreach_summary_plain_text span {
	color: #242424;
	text-align: justify;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.3px;
}

.invite_page_outreach_summary_plain_warning_text span {
	color: #f35f5f;
	text-align: justify;
	font-size: 12px;
	font-style: italic;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.3px;
}

.invite_page_specific_detail_plain_warning_text span {
	color: #f35f5f !important;
	text-align: justify;
	font-size: 12px;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.3px;
}

.invite_page_outreach_summary_plain_lighter_text span {
	color: #808080;
	font-size: 12px;
	font-style: italic;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.3px;
}

.invite_page_outreach_summary_bold_text span {
	color: #242424;
	text-align: justify;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.3px;
}

.invite_page_outreach_summary_bold_underlined_text span {
	color: #242424;
	text-align: justify;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.3px;
	text-decoration: underline !important;
}

body .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
	z-index: 9999;
}

.survey_invite_response_summary_header_style {
	width: 500px;
	margin-top: 30px !important;
	margin-left: 50px !important;
}

.survey_invite_response_summary_body_style {
	width: 500px;
	margin-bottom: 50px;
	margin-top: 30px !important;
	margin-left: 50px !important;
	margin-right: 50px;
	max-width: none !important;
}

.invite_page_col_display {
	display: flex;
}

.survey_invite_open_status_response_summary_heading span {
	display: flex;
	flex-direction: column;
	color: #171818;
	font-size: 20px;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.7px;
}

.survey_invite_open_status_response_summary_heading_failed_invite span {
	display: flex;
	flex-direction: column;
	color: #f35f5f !important;
	font-size: 20px;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.7px;
}

body .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
	z-index: 9999;
}

.survey_invite_response_summary_header_style {
	width: 500px;
	margin-top: 30px !important;
	margin-left: 50px !important;
}

.survey_invite_response_summary_body_style {
	width: 500px;
	margin-bottom: 50px;
	margin-top: 30px !important;
	margin-left: 50px !important;
	margin-right: 50px;
	max-width: none !important;
}

.survey_invite_date_range_open_status {
	width: 404px;
	height: 54px;
}

.survey_invite_date_range_open_status .MuiInputBase-root.Mui-disabled {
	background-color: #fff !important;
	color: #000000 !important;
}

.invite_page_col_display {
	display: flex;
}

body .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
	z-index: 9999;
}

.survey_invite_response_summary_header_style {
	width: 500px;
	margin-top: 30px !important;
	margin-left: 50px !important;
}

.survey_invite_response_summary_body_style {
	width: 500px;
	margin-bottom: 50px;
	margin-top: 30px !important;
	margin-left: 50px !important;
	margin-right: 50px;
	max-width: none !important;
}

.survey_invite_date_range_open_status {
	width: 404px;
	height: 54px;
}
.ls_payslip_lef_container_padding {
	padding: 20px 50px;
}

.ls_payslip_dropzone_background {
	background-color: #fafafb;
}

.ls_payslip_left_cont_maxwidth {
	max-width: 28rem;
}

.ls_payslip_left_cont_maxwidth span {
	color: #242424;
	width: 454px;
	height: 38px;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: -0.7px;
}

.ls_payslip_left_cont_download_button {
	max-width: 15rem;
	float: right;
}

.ls_payslip_left_cont_download_button span {
	font-weight: 500 !important;
}

#ls_payslip_dropzone_background {
	background-color: '#FAFAFB' !important;
}

.form_container_root .form_container_label_content_bold label {
	position: relative;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.1px;
	color: #4f4f4f;
}

.form_container_root .form_container_label_parent {
	position: relative;
	padding-top: 3%;
}

.ls_box_content .MuiFormControl-marginNormal {
	margin-top: 4px;
	margin-bottom: 4px;
}

.ls_box_container_padding_top {
	padding-top: 10px !important;
}

.ls_box_container_padding_right {
	padding-right: 10px !important;
}

.wc_standard_demographic_container {
	position: relative;
	float: right;
	width: 100%;
	margin-right: 20px;
}

.wc_standard_demographic_sub_container {
	position: relative;
	width: 100%;
	height: auto;
	margin-top: -20px;
}

.wc_standard_demographic_sub_container_row {
	position: relative;
	width: 100%;
	height: auto;
	padding-left: 10px !important;
}

.payslip_standard_demographic_sub_container_row {
	position: relative;
	width: 100%;
	height: auto;
	padding-left: 10px !important;
}

.wc_standard_demographic_sub_container_row_title_payslip {
	// position: relative;
	width: 100%;
	padding-bottom: 0;
	padding-left: 10px !important;
}

.titles_for_inputs_for_payslip {
	color: #242424 !important;
}

.wc_custom_field_container {
	position: relative;
	float: right;
	width: 100%;
}

.wc_custom_field_sub_container {
	position: relative;
	width: 100%;
	height: auto;
	margin-top: -20px;
}

.wc_custom_field_sub_container_row {
	position: relative;
	width: 100%;
	height: auto;
	padding-left: 10px !important;
}

.wc_custom_field_sub_container_row_drop_down {
	padding-bottom: 120px !important;
}

.wc_custom_field_sub_container_row_header {
	position: relative;
	width: 100%;
	height: auto;
	padding-left: 10px !important;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.5px;
	color: #4b5152;
	margin-bottom: 10px;
}

.wc_standard_demographic_container .input_container_child {
	/*height: 66px;*/
}

.input_container_horizontal {
	position: relative;
	margin-top: 0;
	height: auto;
	min-height: 60px;
}

.input_container_horizontal_send_survey {
	position: relative;
	margin-top: 0;
	padding-top: 10px;
	height: auto;
	min-height: 155px !important;
}

.message_heading_column_invite_page_open_status span {
	color: #242424;
	font-size: 14px;
	padding-left: 30px;
	padding-bottom: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.3px;
}

.message_heading_column_invite_page_open_status_send_date span {
	color: #242424;
	font-size: 14px;
	padding-bottom: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.3px;
}

// .send_date_time_invite_page_open_status span {
//     display: flex;
//     height: 38px !important;
//     padding-left: 10px;
// }

.input_container_horizontal_send_survey {
	position: relative;
	margin-top: 0;
	padding-top: 10px;
	height: auto;
	min-height: 155px !important;
}

.message_heading_column_invite_page_open_status span {
	color: #242424;
	font-size: 14px;
	padding-left: 30px;
	padding-bottom: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.3px;
}

.message_heading_column_invite_page_open_status_send_date span {
	color: #242424;
	font-size: 14px;
	padding-bottom: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.3px;
}

// .send_date_time_invite_page_open_status span {
//     display: flex;
//     height: 38px !important;
//     padding-left: 10px;
// }

.input_container_horizontal_for_title_payslip {
	position: relative;
	margin-top: 0;
	height: auto;
}

.input_container_horizontal {
	position: relative;
	margin-top: 0;
	height: auto;
	min-height: 60px;
}

.input_container_horizontal .MuiInputBase-input {
	outline: none !important;
	height: 32px;
}

.input_container_horizontal .MuiOutlinedInput-input {
	padding: 8px 8px !important;
}

.input_container_horizontal_input_parent {
	position: relative;
	width: 70%;
	float: right;
	min-height: 50px;
}

.input_container_horizontal_input_parent_send_survey {
	position: relative;
	width: 100%;
	float: right;
	padding-left: 30px;
}

.input_container_horizontal_input_parent_send_survey {
	position: relative;
	width: 100%;
	float: right;
	padding-left: 30px;
}

.input_container_horizontal_input_parent_character_count_payslip {
	position: relative;
	width: 70%;
	float: right;
	color: #4b5152;
	font-size: 12px !important;
	padding-top: 0.6rem;
}

.send_survey_invite_page_child_single_response_section {
	padding: 0px 20px 20px 20px;
}

.cancel_button_hover:hover {
	background-color: #2196f3 !important;
	color: #ffffff !important;
}

.cancel_button_hover {
	color: #2196f3 !important;
	border: 1px solid #c7cbcc !important;
}

.input_box_style_date_time_range_picker {
	width: 183px !important;
	height: 54px !important;
}

.survey_invite_response_details_popup {
	display: flex;
	width: 500px;
	padding: 50px;
	flex-direction: column;
	gap: 30px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0px 0.5px 8px 0px rgba(0, 0, 0, 0.2);
}

.input_container_horizontal_input_parent_character_count_payslip_send_survey {
	position: relative;
	width: 70%;
	float: left;
	padding-left: 30px;
	color: #4b5152;
	font-size: 12px !important;
	padding-top: 0.6rem;
}

.send_survey_invite_page_child_single_response_section {
	padding: 0px 20px 20px 20px;
}

.cancel_button_hover:hover {
	background-color: #2196f3 !important;
	color: #ffffff !important;
}

.cancel_button_hover {
	color: #2196f3 !important;
	border: 1px solid #c7cbcc !important;
}

.input_box_style_date_time_range_picker {
	width: 183px !important;
	height: 54px !important;
}

.survey_invite_response_details_popup {
	display: flex;
	width: 500px;
	padding: 50px;
	flex-direction: column;
	gap: 30px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0px 0.5px 8px 0px rgba(0, 0, 0, 0.2);
}

.input_container_horizontal_input_parent_character_count_payslip_send_survey {
	position: relative;
	width: 70%;
	float: left;
	padding-left: 30px;
	color: #4b5152;
	font-size: 12px !important;
	padding-top: 0.6rem;
}

.input_container_horizontal_input_parent_title_payslip {
	position: relative;
	width: 70%;
	float: right;
	padding-bottom: 0;
}

.input_container_horizontal_input_parent_title_invite {
	position: relative;
	float: right;
	padding-left: 20px;
	padding-bottom: 0;
	padding-bottom: 0;
}

.input_container_horizontal_input_parent_title_invite {
	position: relative;
	float: right;
	padding-left: 20px;
	padding-bottom: 0;
}

.input_container_horizontal_input_parent_payslip {
	position: relative;
	width: 100% !important;
	float: right;
	min-height: 50px;
}

.input_container_horizontal_label_parent {
	position: relative;
	width: 28%;
	float: left;
	height: 50px;
}

.input_container_horizontal_label_parent_payslip_autosize_textarea {
	position: relative;
	width: 28%;
	float: left;
	height: 50px;
}

.input_container_horizontal_label_parent_payslip_autsize_textarea :focus {
	border-color: green !important;
}

.input_container_horizontal_label_parent_title_payslip_title {
	width: 28%;
	float: left;
	height: 27px;
}

.input_container_horizontal_label_parent label {
	height: 50px;
}

.input_container_horizontal_label_parent label span {
	/*    line-height: 50px;*/
	padding-top: 20px;
}

.input_container_horizontal_auto {
	padding-bottom: 10px;
	width: 100%;
	height: auto;
	float: left;
}

.input_container_horizontal_auto .MuiInputBase-input {
	outline: none !important;
	border: 1px solid #dfdbdb;
	border-radius: 4px;
	height: auto;
	min-height: 46px;
}

.input_container_horizontal_auto .Mui-focused .MuiInputBase-input {
	border: 1px solid $primary-color !important;
}

/*.wc_custom_field_sub_container_row .MuiInputBase-input {
    height: auto !important;
}*/

.ls_theme_box_card {
	position: relative;
	height: 100%;
	margin-bottom: 10px !important;
}

.ls_theme_box_card_margin_bottom {
	margin-bottom: 10px !important;
}

.ls_theme_box_card .form_container_label_content {
	line-height: 60px;
}

.ls_theme_box_card .MuiChip-root {
	background-color: $primary-color !important;
	border-radius: 4px;
	color: #ffffff;
}

.ls_theme_box_card .MuiChip-root .MuiChip-deleteIcon {
	color: #ffffff !important;
}

.ls_theme_box_custom_card {
	position: relative;
	height: 100%;
	margin-bottom: 10px !important;
}

.ls_theme_box_custom_card_margin_bottom {
	margin-bottom: 10px !important;
}

.ls_theme_box_custom_card .form_container_label_content {
	line-height: 60px;
}

#wc_groups_status_dropdown .MuiPopover-paper,
#payslip_status_dropdown .MuiPopover-paper,
#wc_status_dropdown .MuiPopover-paper {
	min-width: 18% !important;
}

.ls_theme_badge_active {
	/*background-color: $primary-color !important;
    color: #FFFFFF !important;*/
	border: 2px solid $app_default_primary_color !important;
}

.left_filter_container_clear_all {
	position: absolute;
	right: 16px;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	color: $app_default_primary_color;
	top: 10px;
	width: auto;
	min-width: 20%;
	height: auto;
	min-height: 16px;
}

.left_filter_container_clear_all_delete_icon {
	background: url('/vendors/wovo/icons/common/icon_label_delete.svg') no-repeat
		center center;
	background-size: contain;
	width: 12px;
	height: 16px;
	position: absolute;
	float: right;
	right: 0;
}

.left_filter_container_clear_all_title {
	position: absolute;
	float: right;
	right: 16px;
	padding-top: 2px;
}

.left_filter_container_clear_all_icon {
	font-size: 1.25rem !important;
	padding-left: 6px;
	margin-top: -3px;
}

.ls_theme_active_chip {
	background-color: $primary-color !important;
	margin-right: 8px !important;
	border-radius: 4px !important;
	color: #ffffff !important;
	margin-bottom: 10px;
	height: 24px !important;
}

.ls_theme_active_chip .MuiChip-deleteIcon {
	color: #ffffff !important;
	width: 16px !important;
	height: 16px !important;
}

.ls_theme_active_chip_background {
	background-color: $primary-color !important;
	margin-right: 8px !important;
	color: #ffffff !important;
	margin-bottom: 10px !important;
	height: 24px !important;
	border-radius: 24px !important;
}

.ls_theme_inactive_chip_background {
	background-color: #aad6fa !important;
	margin-right: 8px !important;
	color: #ffffff !important;
	margin-bottom: 10px !important;
	height: 24px !important;
	border-radius: 24px !important;
}

.contact_settings .ls_theme_inactive_chip_background .MuiChip-root {
	background-color: #aad6fa !important;
	color: #ffffff;
}

.ls_filter_button_badge {
	position: absolute;
	background-color: #fa005f;
	min-width: 16px;
	width: auto;
	height: 16px;
	top: 0;
	right: 0;
	padding: 2px;
	border-radius: 50%;
	text-align: center;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: 0.4px;
	color: #ffffff;
}

.ls_theme_tooltip {
	font-size: 0.8rem;
}

.multi_select_dropdown_no_title_label {
	position: absolute;
	top: -7px;
	left: 10px;
	background-color: #ffffff;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	color: #9da3a3;
	padding-left: 6px;
	padding-right: 6px;
}

.ls_input_list_non_title_sub_content .MuiInputLabel-outlined {
	color: #9da3a3 !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	letter-spacing: 0.25px !important;
}

.ls_input_list_non_title_sub_content .MuiOutlinedInput-multiline {
	padding: 15px 14px;
}

.open_draft_invite_page_text span {
	font-weight: 600;
	font-size: 14px;
	color: #242424;
}

.open_draft_invite_page_text_two_three span {
	font-size: 12px;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #242424;
}

.open_draft_invite_page_text span {
	font-weight: 600;
	font-size: 14px;
	color: #242424;
}

.open_draft_invite_page_text_two_three span {
	font-size: 12px;
	line-height: normal;
	letter-spacing: -0.5px;
	color: #242424;
}

.ls_input_list_non_title_sub_content .checkbox {
	width: 18px !important;
	height: 18px !important;
	background: $app_default_primary_color !important;
	border: 4px solid #9da3a3;
	border-radius: 4px;
}

// .wc_standard_demo_dob_margin {
// 	margin-top: -40px !important;
// }

.wc_slider_parent {
	position: relative;
	width: 100%;
	height: auto;
}

.wc_slider_parent_title {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #4f4f4f;
	padding-bottom: 10px;
}

.wc_slider_parent_img {
	position: relative;
	width: 100%;
	height: auto;
}

.wc_slider_parent_content {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: #4b5152;
	padding-top: 10px;
}

.wc_slider_arrow_container {
	position: relative;
	width: 100%;
	height: auto;
}

.wc_slider_arrow_sub_container {
	position: absolute;
	width: auto;
	height: 20px;
	float: right;
	right: 0;
}

.wc_slider_arrow_icon {
	position: relative;
	width: 20px;
	height: 20px;
	float: right;
	margin-left: 12px;
}

.wc_slider_arrow_dot_parent {
	position: relative;
	width: auto;
	height: 20px;
	float: right;
	margin-left: 12px;
}

.wc_slider_arrow_dot {
	position: relative;
	width: 8px;
	height: 8px;
	margin-right: 4px;
	margin-top: 6px;
	background-color: #4b5152;
	border-radius: 4px;
	float: left;
}

.wc_slider_arrow_dot_active {
	background-color: #d7d8d9 !important;
}

.slick-slider {
	margin-bottom: 0 !important;
}

.text_underline {
	text-decoration: underline #4b5152;
}

.ls_badge_date_range_label {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	color: #4b5152;
}

.adminControlsWCHeader {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.4px;
	color: #4b5152;
	margin-bottom: 12px;
}

.adminControlsWCContent {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.4px;
	color: #4b5152;
	padding-left: 8px;
}

.MuiDialogTitle-root {
	padding: 16px 24px 4px 24px !important;
}

.wc_deactivate_popup_content {
	position: relative;
	min-height: 120px;
	min-width: 480px;
	width: 100%;
	height: auto;
}

.wc_deactivate_popup_contacts_selected {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.5px;
	color: #9da3a3;
	margin-bottom: 24px !important;
	margin-top: -4px !important;
}

.wc_deactivate_popup_contacts_icon_text {
	position: absolute;
	float: right;
	width: calc(100% - 52px);
	height: 100%;
	right: 0;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.1px;
}

.wc_deactivate_popup_contacts_icon_container {
	position: relative;
	width: 100%;
	height: 100%;
	float: left;
}

.wc_deactivate_popup_contacts_icon_text_warning {
	padding-right: 6px;
	font-weight: 600;
}

.wc_deactivate_popup_contacts_icon_text_action {
	padding-right: 6px;
	font-weight: 500;
	padding-left: 6px;
}

.wc_reactivate_popup_content {
	position: relative;
	min-height: 100px;
	min-width: 480px;
	width: 100%;
	height: auto;
}

.wc_reactivate_popup_contacts_selected {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.5px;
	color: #9da3a3;
	margin-bottom: 24px !important;
	margin-top: -4px !important;
}

.wc_reactivate_popup_contacts_icon_text {
	position: absolute;
	float: right;
	width: calc(100% - 52px);
	height: 100%;
	right: 0;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.1px;
}

.wc_reactivate_popup_contacts_icon_container {
	position: relative;
	width: 100%;
	height: 100%;
	float: left;
}

.wc_reactivate_popup_contacts_icon_text_warning {
	padding-right: 6px;
	font-weight: 600;
}

/*.wc_reactivate_popup_contacts_icon_text_action {
    padding-right: 6px;
    font-weight: 500;
    padding-left: 6px;
}*/

.clients_filters_row {
	margin-bottom: -10px !important;
}

.wc_job_role_toast_header {
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	color: #ffffff;
}

.wc_job_role_toast_content {
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	color: #ffffff;
}

.connect_report_summary_table_page_label {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #222829;
}

.connect_report_summary_table_helper_text {
	margin-left: 0 !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 10px !important;
	line-height: 11px !important;
	letter-spacing: 0.32px !important;
	color: rgba(0, 0, 0, 0.6) !important;
}

.survey_invite_closed_multiple_response_section {
	border-radius: 4px;
	border: 1px solid var(--primary-gray-scale-lighter, #c7cbcc);
	background: var(--base-surface-white, #fff);
	/* Medium */
	box-shadow: 0px 0.5px 8px 0px rgba(0, 0, 0, 0.2);
	margin-left: 50px;
	margin-right: 50px;
}

.draft_dropdown_section {
	padding-top: 35px;
	display: flex;
}

.open_dropdown_section {
	padding-top: 35px;
	display: flex;
}

.time_zone_text_classname span {
	color: #242424;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.3px;
	align-items: flex-start !important;
}
.dropdown_right_text_content_open_page {
	margin-left: 10px;
	line-height: normal;
}

.dropdown_right_text_content_draft_page {
	margin-left: 10px;
	line-height: normal;
}

.invite_page_open_page_line_divider {
	height: 5px;
	background: #f8f7fa;
}

.invite_page_details_popup_headings span {
	color: #242424;
	text-align: justify;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.5px;
}

.invite_page_open_state_channel span {
	color: #171818;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 19px; /* 118.75% */
	letter-spacing: -0.6px;
}

.draft_dropdown_section_text_section span {
	color: var(--primary-gray-scale-darkest-headline, #171818);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.3px;
}

.survey_invite_closed_multiple_response_section {
	border-radius: 4px;
	border: 1px solid var(--primary-gray-scale-lighter, #c7cbcc);
	background: var(--base-surface-white, #fff);
	/* Medium */
	box-shadow: 0px 0.5px 8px 0px rgba(0, 0, 0, 0.2);
	margin-left: 50px;
	margin-right: 50px;
}

.draft_dropdown_section {
	padding-top: 35px;
	display: flex;
}

.open_dropdown_section {
	padding-top: 35px;
	display: flex;
}

.time_zone_text_classname span {
	color: #242424;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.3px;
	align-items: flex-start !important;
}
.dropdown_right_text_content_open_page {
	margin-left: 10px;
	line-height: normal;
}

.dropdown_right_text_content_draft_page {
	margin-left: 10px;
	line-height: normal;
}

.invite_page_open_page_line_divider {
	height: 5px;
	background: #f8f7fa;
}

.invite_page_details_popup_headings span {
	color: #242424;
	text-align: justify;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.5px;
}

.invite_page_open_state_channel span {
	color: #171818;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 19px; /* 118.75% */
	letter-spacing: -0.6px;
}

.draft_dropdown_section_text_section span {
	color: var(--primary-gray-scale-darkest-headline, #171818);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.3px;
}

.connect_report_summary_table_group_by_label {
	font-weight: 400 !important;
	font-size: 12px !important;
	line-height: 14px !important;
	letter-spacing: 0.4px !important;
	color: #9da3a3 !important;
}

.survey_report_filter_title_label {
	font-size: 20px;
	line-height: 24px;
}

.survey_report_filter_label {
	font-style: normal;
	font-weight: 600;
	letter-spacing: 0.15px;
	color: #4f4f4f;
}

.survey_report_filter_type_label {
	font-size: 14px;
}

.survey_report_filter_clear_button {
	border: 1px solid #d7d8d9 !important;
	border-radius: 4px;
}

.survey_report_filter_button_label {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.survey_report_invite_page {
	background-color: white;
	padding: 50px 30px 50px 30px;
	gap: 15px;
}
.invite_closed_page_survey_invite_send_date_time {
	display: flex !important ;
	padding-left: 5px;
	color: #171818;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 19px; /* 118.75% */
	letter-spacing: -0.6px;
}

.invite_closed_page_child_company_creators_record span {
	// padding-left: 30px;
	padding-top: 20px;
	color: #171818;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 19px; /* 118.75% */
	letter-spacing: -0.6px;
	text-align: left;
}
.survey_report_invite_page_first_section {
	background-color: #fafafc !important;
	margin: 0px 30px 0px 30px !important;
}

.survey_report_invite_page_first_section_closed_mode {
	background-color: #fafafc !important;
	margin: 30px 50px 30px 50px !important;
}

.survey_invite_closed_status_second_section {
	top: 30px;
	left: 30px;
}

.survey_invite_closed_status_second_section_text span {
	padding-left: 70px;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.7px;
	color: #171818;
}

.survey_invite_closed_status_second_section_text_survey_status span {
	padding-left: 50px;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.7px;
	color: #171818;
}

.survey_invite_closed_status_third_section {
	display: flex;
	padding: 20px 35px;
	align-items: flex-start;
	gap: 20px;
	align-self: stretch;
}

.survey_invite_closed_status_third_section span {
	position: relative;
	// width: 300px;
	font-size: 16px;
	line-height: 19px;
	font-weight: 600;
	padding: 0px 10px;
	gap: 4px;
	color: #808080;
	align-items: flex-start;
}

.survey_invite_closed_status_third_section .MuiIconButton-root:hover {
	background-color: transparent !important;
}

.survey_report_invite_page_first_section_last_part {
	background-color: white !important;
	margin: 0px 30px 0px 30px;
}

.survey_report_invite_page_first_section_last_part_closed_state {
	gap: 10px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	position: absolute;
	left: 144px;
	top: 31.5px;
}

.survey_invite_closed_multiple_response_section_inner_content {
	display: flex;
	padding: 10px 20px;
}

.survey_invite_closed_multiple_response_section_inner_content_mul_res span {
	color: #242424;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 19px; /* 118.75% */
	letter-spacing: -0.6px;
}

.survey_invite_inner_reminder_popup {
	width: 480px;
	padding: 30px;
	border-radius: 4px;
	height: 274px;
	gap: 30px;
}

.survey_invite_inner_reminder_popup_text_one span {
	color: #242424;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.3px;
}

.survey_invite_inner_reminder_popup_text_two span {
	color: #242424;
	font-size: 14px;
	font-style: italic;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.3px;
}

.invite_page_target_audience_label {
	display: flex;
	align-items: center;
	gap: 4px;
	align-self: stretch;
}

.invite_page_target_audience_label span {
	color: #242424;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.3px;
}

.button_render_section_invite_open_section_child {
	display: flex;
	padding: 10px 0px;
	justify-content: flex-end;
	align-items: flex-start;
	gap: 10px;
	align-self: stretch;
}

.button_render_section_invite_total_invited_contacts_section {
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	align-self: stretch;
}

.survey_report_invite_page_first_section_inner_content {
	display: flex;
	padding-left: 20px;
	padding-right: 20px;
}

.survey_report_invite_page_first_section_inner_content_closed_mode {
	display: flex;
	padding: 20px 50px;
}

.survey_report_invite_draft_state_icon {
	background: url('/vendors/wovo/icons/quick_insight/draft_invite_page_logo.svg')
		no-repeat center center;
	background-size: contain;
	height: 109px;
	width: 124px;
}

.survey_invite_cancel_cross_small {
	background: url('/vendors/wovo/icons/quick_insight/survey_invite_cancel_cross_small.svg')
		no-repeat center center;
	background-size: contain;
	background-color: #e6eff7;
	height: 24px;
	width: 20px;
}

.survey_invite_cancel_cross_small_sending {
	background: url('/vendors/wovo/icons/quick_insight/survey_invite_cancel_cross_small.svg')
		no-repeat center center;
	background-size: contain;
	height: 24px;
	width: 20px;
}

.survey_invite_calendar_icon_for_period_input {
	background: url('/vendors/wovo/icons/quick_insight/calendar_icon_survey_invite_page.svg')
		no-repeat center center;
	background-size: contain;
	height: 22px;
	width: 20px;
}

.survey_invite_open_status_last_text span {
	color: #242424;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.3px;
}

.survey_invite_list_open_state_background {
	background-color: #e6eff7;
	height: inherit;
	width: inherit;
}

.survey_invite_list_cancelled_state_background {
	background-color: #e2e2e2;
	height: inherit;
	width: inherit;
}

.survey_report_invite_send_popup_image {
	background: url('/vendors/wovo/icons/quick_insight/survey_invite_page_sending_popup_image.svg')
		no-repeat center center;
	background-size: contain;
	height: 63px;
}

.survey_report_invite_send_popup_suptext span {
	color: #292d2d;
	text-align: center !important;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.7px;
}

.survey_report_invite_send_popup_subtext span {
	color: #292d2d;
	text-align: center !important;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.7px;
}

.sent_invite_warning_text_style span {
	color: #292d2d;
	text-align: center !important;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.7px;
}

.survey_invite_state_change_loader {
	background: url('/vendors/wovo/icons/quick_insight/survey_invite_loader_image.gif')
		no-repeat center center;
	background-size: contain;
	height: 65px;
	width: 65px;
}

.survey_report_invite_open_state_qr_code_image {
	background: url('/vendors/wovo/icons/quick_insight/survey_invite_qr_code_image.svg')
		no-repeat center center;
	background-size: contain;
	width: 240px;
	height: 240px;
	color: #000000;
}

.survey_invite_invited_contact_popup_header span {
	color: #171818;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.7px;
}

.survey_invite_invited_contact_popup_header_dialogue span {
	color: #242424;
	text-align: justify;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.5px;
	padding-bottom: 10px;
}

.survey_invite_invited_contact_popup_header_dialogue {
	color: #242424;
	text-align: justify;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.5px;
	padding-bottom: 10px;
}

.survey_invite_invited_total_invited_header span {
	color: #242424;
	text-align: justify;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 19px;
	/* 118.75% */
	letter-spacing: -0.6px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.survey_invite_invited_total_invited_header {
	padding-top: 10px;
	padding-bottom: 10px;
}

.survey_invite_dialogue_content_total_invited {
	padding-bottom: 10px;
}

.survey_invite_total_invited_content_body {
	color: #242424;
	text-align: justify;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.3px;
}

.survey_invite_total_invited_content_body span {
	color: #242424;
	text-align: justify;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.3px;
}

.survey_report_total_invited_contact_stats_image {
	background: url('/vendors/wovo/icons/quick_insight/survey_invite_total_invited_stats_icon.svg')
		no-repeat center center;
	background-size: contain;
	width: 40px;
	height: 32px;
	color: #000000;
}

.survey_report_total_invited_contact_stats_image_disabled {
	background: url('/vendors/wovo/icons/quick_insight/survey_report_total_invited_contact_stats_image_disabled.svg')
		no-repeat center center;
	background-size: contain;
	width: 40px;
	height: 32px;
	color: #000000;
}

.survey_report_invite_open_state_qr_code_icon {
	background: url('/vendors/wovo/icons/quick_insight/survey_invite_download_icon.svg')
		no-repeat center center;
	background-size: contain;
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	color: #000000;
}

.survey_report_invite_survey_status_dropdown_icon {
	background: url('/vendors/wovo/icons/quick_insight/invite_page_survey_status_dropdown_arrow.svg')
		no-repeat center center;
	background-size: contain;
	height: 20px;
	width: 20px;
	background-color: white;
}

.survey_report_invite_page_invited_contact_icon {
	background: url('/vendors/wovo/icons/quick_insight/invited_contact_invite_page_closed_instance.svg')
		no-repeat center center;
	background-size: contain;
	height: 32px;
	width: 40px;
}

.survey_report_invite_page_invited_share_invite_contact_icon {
	background: url('/vendors/wovo/icons/quick_insight/share_invite_icon_invite_page.svg')
		no-repeat center center;
	background-size: contain;
	height: 20px;
	width: 20px;
	padding: 1px 2px;
}

.survey_report_invite_open_state_icon {
	background: url('/vendors/wovo/icons/quick_insight/open_invite_page_logo.svg')
		no-repeat center center;
	background-size: contain;
	height: 109px;
	width: 124px;
}

.survey_report_invite_close_state_icon {
	background: url('/vendors/wovo/icons/quick_insight/close_invite_page_logo.svg')
		no-repeat center center;
	background-size: contain;
	height: 109px;
	width: 124px;
}

.survey_report_invite_copy_icon_disabled {
	background: url('/vendors/wovo/icons/quick_insight/copy_invite_page_disabled.svg')
		no-repeat center center;
	background-size: contain;
	height: 32px;
	width: 40px;
	padding: 2.5px;
}

.survey_report_close_icon_invite_page_open_status {
	background: url('/vendors/wovo/icons/quick_insight/close_icon_invite_page_open_status.svg')
		no-repeat center center;
	background-size: contain;
	height: 24px;
	width: 24px;
}

.survey_invite_download_text_class {
	padding-top: 5px;
}

.survey_invite_open_status_dropdown_classname {
	div {
		div {
			div:hover :focus {
				border: none !important;
			}
			select {
				background-color: #00a080 !important;
				color: #ffffff;
				height: 20px;
				padding: 10.5px 24px;
				option {
					color: #000000;
				}
			}
			svg {
				color: white;
			}
		}
	}
}

.warning_class_for_open_survey span {
	font-weight: 400 !important;
}

.survey_invite_draft_status_dropdown_classname {
	div {
		div {
			select {
				background-color: #ffad4d !important;
				color: #ffffff;
				height: 20px;
				padding: 10.5px 24px;
				option {
					color: black !important;
					font-size: 14px;
				}
				border: none;
			}
			svg {
				color: white;
			}
		}
	}
}

.survey_invite_draft_status_dropdown_disabled_classname {
	div {
		div {
			.Mui-disabled .MuiInputBase-input {
				color: white !important;
			}

			.MuiSelect-iconOutlined {
				color: white !important;
			}
			select {
				background-color: #fad5aa !important;
				color: #ffffff;
				height: 20px;
				padding: 10.5px 24px;
				option {
					color: #ffffff !important;
					font-size: 14px;
				}
				border: none !important;
			}
			svg {
				color: white !important;
			}
		}
	}
}

.survey_invite_qr_image_heading span {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1 0 0;
	align-self: stretch;
	color: #171818;
	font-size: 20px;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.7px;
}

.survey_invite_qr_image_subtext span {
	align-self: stretch;
	color: #171818;
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	letter-spacing: -0.6px;
}

.survey_report_invite_copy_icon_enabled {
	background: url('/vendors/wovo/icons/quick_insight/copy_invite_page_enabled.svg')
		no-repeat center center;
	background-size: contain;
	height: 32px;
	width: 40px;
	padding: 2.5px;
	cursor: pointer;
}

.survey_report_invite_qrcode_icon_disabled {
	background: url('/vendors/wovo/icons/quick_insight/qr_code_invite_page_disabled.svg')
		no-repeat center center;
	background-size: contain;
	height: 32px;
	width: 40px;
	padding: 2.5px;
}

.survey_report_invite_qrcode_icon_enabled {
	background: url('/vendors/wovo/icons/quick_insight/qr_code_invite_page_enabled.svg')
		no-repeat center center;
	background-size: contain;
	height: 32px;
	width: 40px;
	padding: 2.5px;
	cursor: pointer;
}

.survey_report_invite_assigned_company_icon_enabled {
	background: url('/vendors/wovo/icons/quick_insight/assigned_company_invite_page_enabled.svg')
		no-repeat center center;
	background-size: contain;
	height: 32px;
	width: 40px;
	padding: 2.5px;
	cursor: pointer;
}

.header_client_parent {
	position: relative;
	width: auto;
	height: 100%;
	display: flex;
	align-content: center;
	justify-content: center;
}

.header-client-popover {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	align-items: center;
	letter-spacing: -0.7px;
	color: #4b5152;
}

.dropdown-arrow-triangle {
	width: 10px;
	height: 5px;
	background: url('/vendors/wovo/direction-down.png') no-repeat center center;
	float: right;
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}
.disabled_status {
	pointer-events: none;
	opacity: 0.3;
}

.reset_registration_table .MuiTableRow-root td .status_label span {
	color: #ffffff !important;
}

.reset_registration_table
	.MuiTableRow-root
	td
	.reset_registration_table_column_status_label
	span {
	color: #fff !important;
}

.reset_registration_table .MuiTableRow-head {
	height: 60px !important;
}

.reset_registration_table .MuiTableRow-body {
	height: 43px !important;
}

.reset_registration_table .MuiTableRow-root:hover {
	background: none !important;
}
